/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRTooltip, getIcon } from '@SLR/shared-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PROFILE_EMAIL_UPDATE_PATH } from 'configs';
import { FC } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './ProfileEmailFormField.scss';

type ProfileEmailFormFieldProps = {
  emailAddress?: string;
  emailVerified?: boolean;
};

const ProfileEmailFormField: FC<ProfileEmailFormFieldProps> = ({
  emailAddress,
  emailVerified = true
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'profilePage.email'
  });

  return (
    <div className="profile-email-field">
      <Form.Label htmlFor="email">{t('email')}</Form.Label>
      <InputGroup>
        <Form.Control
          id="email"
          placeholder={t('email')}
          className="form-control-lg"
          defaultValue={emailAddress}
          disabled
          autoComplete="false"
        />
        <InputGroup.Text>
          <SLRTooltip
            content={t(emailVerified ? 'verified' : 'notVerified')}
            trigger={['hover', 'focus']}
            placement="top"
          >
            <FontAwesomeIcon
              icon={getIcon(
                'fal',
                emailVerified ? 'envelope-circle-check' : 'exclamation-triangle'
              )}
              className={emailVerified ? 'verified' : 'unverified'}
              size="lg"
            />
          </SLRTooltip>
        </InputGroup.Text>
      </InputGroup>
      <Form.Text>
        <Link to={PROFILE_EMAIL_UPDATE_PATH}>{t('title')}</Link>
      </Form.Text>
    </div>
  );
};

export default ProfileEmailFormField;
