/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { Ownership } from '@SLR/media-service-sdk';
import {
  SLRFormInfo,
  SLRLogoUpload,
  SLRModal,
  SLRTextEditor,
  emptyAsNull,
  getIcon,
  isEmptyOrNull,
  showErrorToast,
  useUpdateMediaItems
} from '@SLR/shared-library';
import { Organization, OrganizationType } from '@SLR/user-service-full-sdk';
import { yupResolver } from '@hookform/resolvers/yup';
import { useChangeOrganization, useCreateOrganization } from 'hooks';
import { FC, useCallback, useMemo, useState } from 'react';
import { Accordion, Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  DESCRIPTION_MAX_CHAR_LENGTH,
  DETAILED_DESCRIPTION_MAX_CHAR_LENGTH,
  OrganizationInput,
  OrganizationInputSchema
} from '..';

import './OrganizationModal.scss';

type OrganizationModalProps = {
  suggestedOrganizationName?: string;
  organization?: Organization;
  buttonLabel?: string;
  onCreate?: (id: string) => void;
  onClose: () => void;
};

const OrganizationModal: FC<OrganizationModalProps> = ({
  suggestedOrganizationName,
  organization,
  buttonLabel,
  onCreate,
  onClose
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'organizationsPage.createEdit'
  });

  const { mutate: createOrganization, isPending: isCreateOrganizationLoading } =
    useCreateOrganization(onCreate === undefined);
  const { mutate: changeOrganization, isPending: isUpdateOrganizationLoading } =
    useChangeOrganization();

  const { mutate: updateMedia } = useUpdateMediaItems({
    onDeleteError: () => showErrorToast('toasts.deleteMediaItem.error'),
    onSaveError: () => showErrorToast('toasts.saveMediaItem.error')
  });

  const [isLogoDialogBusy, setIsLogoDialogBusy] = useState<boolean>(false);

  const isLoading = useMemo(
    () => isCreateOrganizationLoading || isUpdateOrganizationLoading,
    [isCreateOrganizationLoading, isUpdateOrganizationLoading]
  );

  const isEdit = useMemo(() => organization !== undefined, [organization]);

  /**
   * NOTE: Currently, user portal only supports the creation of user organization, however organizations of other types can be edited here
   * As other organizations require an email address we need to pass this information to the schema
   */
  const isUserOrganization = useMemo(
    () => Boolean(organization?.type === OrganizationType.User) || !isEdit,
    [isEdit, organization?.type]
  );

  const {
    formState: { errors },
    handleSubmit,
    control,
    register,
    watch,
    reset
  } = useForm<OrganizationInput>({
    mode: isEdit ? 'onChange' : 'onSubmit',
    defaultValues: {
      ...organization,
      name: suggestedOrganizationName ?? organization?.name ?? null,
      mediaItemId: organization?.logoId ?? null
    },
    resolver: yupResolver(OrganizationInputSchema(isUserOrganization))
  });

  const descriptionCount = watch('description')?.length ?? 0;

  const handleCreateUpdateOrganization = useCallback(
    (data: OrganizationInput) => {
      if (isEdit && organization) {
        changeOrganization(
          {
            organizationId: organization.id,
            // Allow null values, as the SDK only allows undefined but in BE its defined as nullable
            // eslint-disable-next-line
            // @ts-ignore
            changeOrganizationRequestDto: data
          },
          {
            onSuccess: () => {
              updateMedia({
                newMediaIds: !isEmptyOrNull(data.mediaItemId)
                  ? [data.mediaItemId]
                  : [],
                oldMediaIds: !isEmptyOrNull(organization.logoId)
                  ? [organization.logoId]
                  : []
              });
              onClose();
            }
          }
        );
      } else {
        createOrganization(
          {
            organizationType: OrganizationType.User,
            // Allow null values, as the SDK only allows undefined but in BE its defined as nullable
            // eslint-disable-next-line
            // @ts-ignore
            createOrganizationRequestDto: data
          },
          {
            onSuccess: ({ id }) => {
              updateMedia({
                newMediaIds: !isEmptyOrNull(data.mediaItemId)
                  ? [data.mediaItemId]
                  : []
              });
              if (onCreate) onCreate(id);
              onClose();
            }
          }
        );
      }
    },
    [
      changeOrganization,
      createOrganization,
      isEdit,
      onClose,
      onCreate,
      organization,
      updateMedia
    ]
  );

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  return (
    <SLRModal
      isLoading={isLoading}
      enforceFocus={false}
      backdrop="static"
      title={t(isEdit ? 'edit' : 'create')}
      size="lg"
      fullscreen="md-down"
      isOpen
      onClose={handleClose}
      footerContent={
        <Row className="w-100 m-0 gap-3 align-items-center justify-content-end">
          <Col xs="auto">
            <SLRFormInfo className="w-auto" text={t('mandatoryField')} />
          </Col>
          <Col xs="12" sm="auto" className="p-0 text-end">
            <Button
              type="submit"
              size="lg"
              onClick={handleSubmit(handleCreateUpdateOrganization)}
              disabled={isLoading || isLogoDialogBusy}
            >
              {buttonLabel ?? t(isEdit ? 'save' : 'create')}
            </Button>
          </Col>
        </Row>
      }
      onHide={handleClose}
    >
      <Form
        noValidate
        id="create-organization"
        className="organization-modal px-md-3 px-lg-4"
        onSubmit={handleSubmit(handleCreateUpdateOrganization)}
      >
        <Row className="mb-md-4 pt-4">
          <Col xs="12">
            <h2 className="mb-3">{t(isEdit ? 'edit' : 'new')}</h2>
          </Col>
          <Col xs="12" className="picture-editor-wrapper text-lg-end">
            <Form.Label htmlFor="logo" aria-hidden className="d-none">
              {t('logoPicture')}
            </Form.Label>
            <SLRLogoUpload
              id="logo"
              name="mediaItemId"
              control={control}
              mediaOwner={{
                organizationId: isEdit ? organization?.id : undefined,
                owner: isEdit ? Ownership.Organization : Ownership.User
              }}
              icons={{
                fallbackIcon: getIcon('fal', 'image-slash'),
                uploadIcon: getIcon('fal', 'cloud-upload'),
                deleteIcon: getIcon('fal', 'trash-alt'),
                rotateIcon: getIcon('fal', 'redo')
              }}
              labels={t('logo', {
                returnObjects: true
              })}
              isBusy={setIsLogoDialogBusy}
            />
          </Col>
          <Col xs="12" lg="8">
            <div>
              <Form.Label htmlFor="name">{t('name')}*</Form.Label>
              <Form.Control
                id="name"
                type="text"
                className="form-control-lg"
                autoComplete="true"
                isInvalid={!!errors.name}
                disabled={isLoading}
                {...register('name')}
              />
              <SLRFormInfo
                text={errors?.name?.message}
                isInvalid={!!errors.name}
              />
            </div>
          </Col>
          <Col xs="12" lg="10">
            <div className="mt-4">
              <div className="form-label">{t('description')}*</div>
              <Accordion defaultActiveKey="0" className="description">
                <Accordion.Item eventKey="0">
                  <Accordion.Header
                    as={Form.Label}
                    className="w-100"
                    htmlFor="description"
                  >
                    <span
                      className={
                        errors.description ? 'text-danger' : 'text-primary'
                      }
                    >
                      {t('shortDescription')}*
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Form.Control
                      id="description"
                      as="textarea"
                      rows={4}
                      className="form-control-lg"
                      isInvalid={!!errors.description}
                      disabled={isLoading}
                      maxLength={DESCRIPTION_MAX_CHAR_LENGTH}
                      {...register('description')}
                    />
                    <SLRFormInfo
                      isInvalid={!!errors.description}
                      counter={{
                        count: descriptionCount,
                        maxCount: DESCRIPTION_MAX_CHAR_LENGTH
                      }}
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header
                    as={Form.Label}
                    className="w-100 text-primary"
                    htmlFor="detailedDescription"
                  >
                    <span
                      className={
                        errors.detailedDescription
                          ? 'text-danger'
                          : 'text-primary'
                      }
                    >
                      {t('detailedDescription')}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <SLRTextEditor
                      id="detailedDescription"
                      control={control}
                      name="detailedDescription"
                      maxLength={DETAILED_DESCRIPTION_MAX_CHAR_LENGTH}
                      isInvalid={!!errors.detailedDescription}
                      rows={10}
                      errorMessage={errors.detailedDescription?.message}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <SLRFormInfo
                isInvalid={!!errors.description}
                text={errors.description?.message}
              />
            </div>
          </Col>
          <Col xs="12" lg="7">
            <Form.Group controlId="websiteUrl" className="mt-4">
              <Form.Label>{t('websiteUrl')}</Form.Label>
              <Form.Control
                type="url"
                className="form-control-lg"
                isInvalid={!!errors.websiteUrl}
                disabled={isLoading}
                {...register('websiteUrl', {
                  setValueAs: (value) =>
                    !isEmptyOrNull(value) && !value.startsWith('http')
                      ? 'https://' + value
                      : emptyAsNull(value)
                })}
              />
              <SLRFormInfo
                text={errors?.websiteUrl?.message}
                isInvalid={!!errors.websiteUrl}
              />
            </Form.Group>
          </Col>
          <Col xs="12" lg="7">
            <Form.Group controlId="email" className="mt-4">
              <Form.Label>
                {t('email')}
                {isUserOrganization ? '' : '*'}
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control-lg"
                autoComplete="true"
                isInvalid={!!errors.email}
                disabled={isLoading}
                {...register('email', {
                  setValueAs: emptyAsNull
                })}
              />
              <SLRFormInfo
                text={errors?.email?.message}
                isInvalid={!!errors.email}
              />
            </Form.Group>
          </Col>
          <Col xs="12" lg="7">
            <Form.Group controlId="phoneNumber" className="mt-4">
              <Form.Label>{t('phoneNumber')}</Form.Label>
              <Form.Control
                type="text"
                className="form-control-lg"
                isInvalid={!!errors.phoneNumber}
                disabled={isLoading}
                {...register('phoneNumber', {
                  setValueAs: emptyAsNull
                })}
              />
              <SLRFormInfo
                text={errors?.phoneNumber?.message}
                isInvalid={!!errors.phoneNumber}
              />
            </Form.Group>
          </Col>
          <Col xs="12" lg="7">
            <Form.Group controlId="address" className="mt-4">
              <Form.Label>{t('address')}</Form.Label>
              <Form.Control
                type="text"
                className="form-control-lg"
                autoComplete="false"
                isInvalid={!!errors.address}
                disabled={isLoading}
                {...register('address', {
                  setValueAs: emptyAsNull
                })}
              />
              <SLRFormInfo
                text={errors?.address?.message}
                isInvalid={!!errors.address}
              />
            </Form.Group>
          </Col>
        </Row>
        {/* Add an invisible form submit button to ensure pressing enter submits the form */}
        <input
          type="submit"
          className="d-none"
          aria-hidden="true"
          title={t(isEdit ? 'edit' : 'new')}
        />
      </Form>
    </SLRModal>
  );
};

export default OrganizationModal;
