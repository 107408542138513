/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRFormInfo, SLRLoadingButton } from '@SLR/shared-library';
import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type FormFooterProps = {
  isLoading?: boolean;
  isDisabled?: boolean;
};

export const FormFooter: FC<FormFooterProps> = ({
  isLoading = false,
  isDisabled = false
}) => {
  const { t } = useTranslation();

  return (
    <Row className="gap-3">
      <Col xs="12">
        <SLRFormInfo text={t('form.mandatoryField')} />
      </Col>

      <Col xs="12">
        <SLRLoadingButton isLoading={isLoading} disabled={isDisabled}>
          {t('form.apply')}
        </SLRLoadingButton>
      </Col>
    </Row>
  );
};
