/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRSpinner, isEmptyOrNull } from '@SLR/shared-library';
import { APP_URL, INVITATION_HANDLE } from 'configs';
import { useCheckInvitation } from 'hooks';
import { useQueryParam } from 'providers';
import { FC, useEffect, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Invalid, InvitationModal } from '.';

import './Invitation.scss';

const InvitationCheckPage: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'invitationsPage'
  });

  const { invitationQuery } = useQueryParam();
  const { token = '', organizationId = '' } = invitationQuery || {};

  const { isPending: isCheckLoading, isError: isCheckError } =
    useCheckInvitation(token, organizationId);

  const hasMissingParams = useMemo(
    () => isEmptyOrNull(token) || isEmptyOrNull(organizationId),
    [organizationId, token]
  );

  const isInvalid = useMemo(
    () => isCheckError || hasMissingParams,
    [isCheckError, hasMissingParams]
  );

  useEffect(() => {
    if (!isCheckLoading && !isCheckError && !isInvalid) {
      window.location.replace(
        `${APP_URL}${INVITATION_HANDLE}?organizationId=${organizationId}&token=${token}`
      );
    }
  }, [isCheckError, isCheckLoading, isInvalid, organizationId, token]);

  return (
    <Container className="invitation" fluid>
      {isCheckLoading && <SLRSpinner variant="light" />}
      {isInvalid && (
        <InvitationModal title={t('invalid.title')}>
          <Invalid />
        </InvitationModal>
      )}
    </Container>
  );
};

export default InvitationCheckPage;
