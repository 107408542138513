/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { getIcon, hasHighContrast } from '@SLR/shared-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryParam } from 'providers';
import { FC, useMemo } from 'react';
import { Button, Container } from 'react-bootstrap';

import './SolutionBar.scss';

/**
 * SolutionBar uses a similar url pattern as used for the invitation flow: solution_uri, solution_name and solution_color
 * SolutionBar will not show up in the invitation flow, as in App the current path is checked and the SolutionBar
 * is only shown when the user is on profile or organizations page
 */
const SolutionBar: FC = () => {
  const { solutionBarQuery } = useQueryParam();
  const { solutionURL, solutionName, solutionColor } = solutionBarQuery || {};

  const useDarkButton = useMemo(() => {
    if (solutionColor) return hasHighContrast(solutionColor);
  }, [solutionColor]);

  return (
    <div className="solution-bar" style={{ backgroundColor: solutionColor }}>
      <Container className="button-container">
        <Button
          className={`back-button ${useDarkButton ? 'dark' : ''}`}
          variant="outline-primary"
          href={solutionURL}
        >
          <FontAwesomeIcon icon={getIcon('fal', 'chevron-left')} />
          {solutionName}
        </Button>
      </Container>
    </div>
  );
};

export default SolutionBar;
