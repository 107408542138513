/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowsRotate,
  faCheckCircle,
  faChevronLeft,
  faClose,
  faCloudUpload,
  faDownload,
  faEllipsisStroke,
  faEnvelope,
  faEnvelopeCircle,
  faEnvelopeCircleCheck,
  faExclamationTriangle,
  faGlobe,
  faImageSlash,
  faInfoCircle,
  faKey,
  faLocationDot,
  faLongArrowUp,
  faPaperPlane,
  faPencil,
  faPhoneCircle,
  faPlus,
  faRedo,
  faSignOutAlt,
  faTimesCircle,
  faTrashAlt,
  faTrashCan,
  faTriangleExclamation,
  faUser,
  faUserGroup,
  faUserSlash,
  faUsers,
  faQuestionCircle as falQuestionCircle
} from '@fortawesome/pro-light-svg-icons';
import {
  faArrowLeft,
  faBan,
  faQuestionCircle,
  faSearch,
  faShieldCheck
} from '@fortawesome/pro-regular-svg-icons';
import { faEye, faEyeSlash, faNotdef } from '@fortawesome/pro-solid-svg-icons';

// Note: do not forget to update icon library in open source folder
library.add(
  faNotdef,
  faCheckCircle,
  faClose,
  faUserGroup,
  faEnvelope,
  faCloudUpload,
  faInfoCircle,
  faLongArrowUp,
  faRedo,
  faTrashAlt,
  faTriangleExclamation,
  faSignOutAlt,
  faUser,
  faUsers,
  faChevronLeft,
  faArrowLeft,
  faPlus,
  faSearch,
  faQuestionCircle,
  faTrashCan,
  faPencil,
  faUserSlash,
  faKey,
  faEllipsisStroke,
  faEnvelopeCircle,
  faGlobe,
  faLocationDot,
  faPhoneCircle,
  faPaperPlane,
  faPlus,
  falQuestionCircle,
  faTimesCircle,
  faArrowsRotate,
  faDownload,
  faEye,
  faEyeSlash,
  faEnvelopeCircleCheck,
  faExclamationTriangle,
  faBan,
  faShieldCheck,
  faImageSlash
);
