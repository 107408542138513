/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

export const profilePage = {
  title: 'Profil',
  pages: {
    yourProfile: 'Dein Profil',
    changeEmail: 'E-Mail ändern',
    changePassword: 'Passwort ändern',
    security: 'Sicherheit',
    dataPrivacy: 'Datenschutz'
  },
  alerts: {
    email: {
      resend: 'E-Mail erneut schicken',
      cancel: 'Doch nicht ändern',
      confirm: {
        title: 'Bitte bestätige deine E-Mail-Adresse',
        text: 'Wir haben dir hierzu eine <strong>Anleitung an {{email}}</strong> geschickt. Falls du die Nachricht nicht erhalten hast, <strong>prüfe bitte deinen Spam-Ordner</strong> oder lass dir eine neue E-Mail schicken.'
      },
      new: {
        title: 'Bitte bestätige deine neue E-Mail-Adresse',
        text: 'Deine neue E-Mail-Adresse <strong>muss bestätigt werden, um die Änderung abzuschließen.</strong> Wir haben dir hierzu eine <strong>Anleitung  an {{email}}</strong> geschickt. Falls du die Nachricht nicht erhalten hast, <strong>prüfe bitte deinen Spam-Ordner</strong> oder lass dir eine neue E-Mail schicken.'
      }
    }
  },
  details: {
    title: 'Dein Profil',
    profilePicture: 'Profilbild',
    picture: {
      deletionConfirmationTitle: 'Profilbild entfernen?',
      deletionConfirmationQuestion: 'Soll dein Profilbild gelöscht werden?',
      acceptDeletionConfirmation: 'Ja, entfernen!',
      declineDeletionConfirmation: 'Nein',
      discardChanges: 'Verwerfen',
      acceptChanges: 'Übernehmen',
      tooLargeFile: {
        title: 'Bild zu groß (max. 5 MB)',
        content:
          'Das ausgewählte Bild ist leider zu groß. Bitte wähle ein Bild mit maximal 5 MB aus.'
      },
      invalidFileType: {
        title: 'Dateiformat nicht unterstützt',
        content:
          'Leider unterstützen wir das Format der ausgewählten Datei nicht. Bitte wähle ein Bild im Format “jpg”, “jpeg” oder “png”.'
      },
      uploadLogoAlt: 'Rundes Bild einer Kamera',
      defaultAlt: 'Rundes Profilbild',
      uploadButtonLabel:
        'Taste zur Auswahl eines neuen Profilbildes, welches hochgeladen werden soll',
      deleteButtonLabel: 'Lösch-Taste',
      rotateButtonLabel: 'Dreh-Taste'
    },
    firstName: 'Vorname',
    lastName: 'Nachname',
    contact: 'Kontakt',
    phoneNumber: 'Telefon',
    required: 'Bitte gib Deinen {{field}} an.',
    notEmpty: '{{field}} kann nicht leer sein.'
  },
  email: {
    title: 'E-Mail ändern',
    email: 'E-Mail',
    password: 'Passwort',
    totp: 'Sicherheitscode',
    verified: 'Bestätigte E-Mail',
    notVerified: 'Noch nicht bestätigte E-Mail',
    new: 'Neue E-Mail-Adresse',
    required: 'Bitte gib eine E-Mail-Adresse an.',
    invalid: 'Bitte gib eine gültige E-Mail-Adresse an.',
    equal: ' Bitte gib eine andere E-Mail-Adresse an.',
    passwordRequired:
      'Bitte gib dein aktuelles Passwort ein, um deine E-Mail zu ändern.',
    totpRequired:
      'Bitte gib den angezeigten Code aus deiner Authenticator-App an.'
  },
  password: {
    title: 'Passwort ändern',
    reset: 'Passwort zurücksetzen',
    default:
      'Wähle “Passwort zurücksetzen” und wir senden eine Anleitung an deine E-Mail-Adresse.',
    identityProvider:
      'Du hast dich bei uns mit deinem {{identityProvider}}-Konto registriert. Bitte besuche {{identityProvider}}, um dein {{identityProvider}}-Passwort zu ändern.',
    connectingWord: 'oder'
  },
  security: {
    title: 'Sicherheit',
    heading: 'Zwei-Faktor-Authentifizierung',
    description:
      'Mit der Zwei-Faktor-Authentifizierung (2FA) bleibt dein Konto sicher, selbst wenn jemand dein Passwort kennt. Ein einmaliger Code dient als <strong>zusätzlicher Schutz</strong> bei der Anmeldung. Den Code erhältst du über eine App auf deinem Smartphone.',
    disableText:
      'Wähle “Deaktivieren”, um die Zwei-Faktor-Authentifizierung auszuschalten.',
    activateText:
      'Wähle “Aktivieren” und wir erklären dir Schritt für Schritt, was zu tun ist!',
    active: 'Aktiviert',
    activate: 'Aktivieren',
    deactive: 'Deaktiviert',
    deactivate: 'Deaktivieren',
    cancel: 'Abbrechen',
    confirm: {
      deactivate: {
        title: '2FA deaktivieren?',
        content:
          'Der Schutz deines Kontos wird verringert und du kannst nicht mehr auf Lösungen zugreifen, die eine Zwei-Faktor-Authentifizierung voraussetzen.'
      }
    }
  },
  dataPrivacy: {
    title: 'Datenschutz',
    requestReport: 'Datenbericht anfordern',
    requestDescription:
      'Fordere einen Datenbericht an und erhalte eine Kopie aller Daten, die wir von dir gespeichert haben. Den Link zum Datenbericht erhältst du <strong>innerhalb von 48 Stunden an deine E-Mail-Adresse</strong>.',
    deleteAccount: 'Konto löschen',
    deleteDescription:
      'Unzufrieden oder Fragen? Vielleicht finden wir gemeinsam eine Lösung. Wende dich hierzu gerne an <0>unseren Support</0>.<br></br> <br></br> Wenn du dein Konto löschst, werden dein Konto und deine bei uns gespeicherten Daten <strong>unwiderruflich</strong> entfernt. Bitte beachte, dass nach der Löschung auch der <strong>Zugang zu allen verknüpften Lösungen verloren geht</strong>.',
    deleteConfirmation: {
      title: 'Konto unwiderruflich löschen?',
      content:
        'Die Löschung deines Kontos kann nicht rückgängig gemacht werden. Möchtest du fortfahren?',
      confirm: 'Konto löschen',
      cancel: 'Abbrechen'
    }
  },
  deleted: {
    message:
      'Dein Konto wurde gelöscht.<br></br>Wir bedanken uns für deine Zeit<br></br> bei DEUTSCHLAND.DIGITAL!',
    toLandingPage: 'Zu DEUTSCHLAND.DIGITAL'
  }
};
