/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

export const SOLUTION_URL_PARAM = 'solution_uri';
export const SOLUTION_NAME_PARAM = 'solution_name';
export const SOLUTION_COLOR_PARAM = 'solution_color';
export const SHOW_ENCRYPTION_PARAM = 'showEncryption';
export const SHOW_ORGANIZATION_EDIT_PARAM = 'showOrgEdit';
export const REDIRECT_URL_PARAM = 'redirect_uri';
export const ORGANIZATION_NAME_PARAM = 'organization_name';
export const ORGANIZATION_TYPES_PARAM = 'types';
export const INVITATION_TOKEN_PARAM = 'token';
export const ORGANIZATION_ID_PARAM = 'organizationId';
export const INVITATION_ACCEPT_IMMEDIATELY_PARAM = 'acceptImmediately';
