/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { EMAIL_PATTERN_VALIDATION, isEmptyOrNull } from '@SLR/shared-library';
import { UpdateUserProfileRequestDto } from '@SLR/user-service-full-sdk';
import { t } from 'i18next';
import { ObjectSchema, object, string } from 'yup';

export type ProfileInput = Nullable<UpdateUserProfileRequestDto>;

export const ProfileInputSchema = (): ObjectSchema<ProfileInput> =>
  object().shape({
    firstName: string()
      .required(
        t('profilePage.details.required', {
          field: t('profilePage.details.firstName')
        })
      )
      .test(
        'isEmpty',
        t('profilePage.details.notEmpty', {
          field: t('profilePage.details.firstName')
        }),
        (value) => !isEmptyOrNull(value)
      ),
    lastName: string()
      .required(
        t('profilePage.details.required', {
          field: t('profilePage.details.lastName')
        })
      )
      .test(
        'isEmpty',
        t('profilePage.details.notEmpty', {
          field: t('profilePage.details.lastName')
        }),
        (value) => !isEmptyOrNull(value)
      ),
    homeGeoAreaId: string().default(''),
    phoneNumber: string().default(''),
    pictureId: string().nullable()
  });

export type EmailFormInput = {
  newEmail: string;
  password?: string;
  totp?: string;
};

export const EmailFormInputSchema = (
  usesSocialLogin?: boolean,
  otpEnabled?: boolean,
  emailAddress?: string,
  pendingNewEmailAddress?: string
): ObjectSchema<EmailFormInput> =>
  object().shape({
    newEmail: string()
      .matches(EMAIL_PATTERN_VALIDATION, t('profilePage.email.invalid'))
      .required(t('profilePage.email.required'))
      .test(
        'equal',
        t('profilePage.email.equal'),
        (value) => value !== emailAddress && value !== pendingNewEmailAddress
      ),
    password: string().test(
      'required',
      t('profilePage.email.passwordRequired'),
      (value) => usesSocialLogin || !!value
    ),
    totp: string().test(
      'required',
      t('profilePage.email.totpRequired'),
      (value) => !otpEnabled || !!value
    )
  });
