/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  SLRLabelIcon,
  SLRMediaSize,
  SLRMobileNavBar,
  SLRNavBar,
  SLRProfileDropdown,
  getIcon,
  useAuth,
  useGetMediaItem,
  useWindowSize
} from '@SLR/shared-library';
import { UserPortalLogo } from 'assets';
import {
  DEFAULT_PROFILE_IMAGE_PATH,
  MOBILE_VIEW_WIDTH,
  ORGANIZATIONS_PATH,
  PROFILE_PATH
} from 'configs';
import { useGetCurrentUserProfile } from 'hooks';
import { FC, useMemo } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './NavBar.scss';

const NavBar: FC = () => {
  const { t } = useTranslation();

  const { isAuthenticated, logout } = useAuth();
  const { isMobileView, width } = useWindowSize(MOBILE_VIEW_WIDTH);

  const { data: user, isPending: isUserLoading } = useGetCurrentUserProfile();
  const { data: profilePicture, isFetching: isUserPictureLoading } =
    useGetMediaItem(user?.pictureId, SLRMediaSize.Small);

  const isLoading = isAuthenticated && (isUserLoading || isUserPictureLoading);

  const navigationItems = useMemo(
    () => [
      {
        to: PROFILE_PATH,
        label: t('profile'),
        icon: getIcon('fal', 'user')
      },
      {
        to: ORGANIZATIONS_PATH,
        label: t('organizations'),
        icon: getIcon('fal', 'users')
      }
    ],
    [t]
  );

  return (
    <>
      <SLRNavBar
        appLogo={<UserPortalLogo />}
        appURL={isAuthenticated ? PROFILE_PATH : window.location.href}
        navItems={
          isMobileView || !isAuthenticated ? undefined : navigationItems
        }
        navItemsOptions={{
          useIncludesForIsActive: true
        }}
        showToggle={!isMobileView}
        profileDropdown={
          isAuthenticated ? (
            <SLRProfileDropdown
              keepNamesVisible={width > 450}
              isLoading={isLoading}
              lastName={user?.lastName}
              firstName={user?.firstName}
              profilePicture={profilePicture?.url}
              profilePictureFallback={DEFAULT_PROFILE_IMAGE_PATH}
            >
              <NavDropdown.Item onClick={() => logout()}>
                <SLRLabelIcon
                  icon={getIcon('fal', 'sign-out-alt')}
                  label={t('navBar.logout')}
                />
              </NavDropdown.Item>
            </SLRProfileDropdown>
          ) : undefined
        }
      />
      {isAuthenticated && isMobileView && (
        <SLRMobileNavBar
          navItems={navigationItems}
          navItemsOptions={{
            isIconVisible: true,
            useIncludesForIsActive: true
          }}
        />
      )}
    </>
  );
};
export default NavBar;
