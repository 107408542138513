/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRFooter, SLRFooterItem } from '@SLR/shared-library';
import { bmel } from 'assets';
import {
  CONTACT_URL,
  FAQ_URL,
  IMPRINT_PATH,
  LEGAL_PATH,
  PRIVACY_POLICY_PATH,
  SLR_WEBSITE_URL,
  SUPPORT_URL,
  TERMS_OF_USE_PATH
} from 'configs';
import { FC, memo } from 'react';
import { Image, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Footer: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'footer'
  });

  return (
    <SLRFooter className="footer">
      <Row>
        <SLRFooterItem title={t('links.title')} col={3}>
          <ul>
            <li>
              <Link to={`${LEGAL_PATH}/${PRIVACY_POLICY_PATH}`}>
                {t('links.privacyPolicy')}
              </Link>
            </li>
            <li>
              <Link to={`${LEGAL_PATH}/${TERMS_OF_USE_PATH}`}>
                {t('links.termsOfUse')}
              </Link>
            </li>
            <li>
              <Link to={`${LEGAL_PATH}/${IMPRINT_PATH}`}>
                {t('links.imprint')}
              </Link>
            </li>
          </ul>
        </SLRFooterItem>
        <SLRFooterItem title={t('help.title')} col={4}>
          <p>{t('help.contactUs')}</p>
          <p> {t('help.lookingForward')}</p>
          <ul>
            <li>
              <Link to={CONTACT_URL} target="_blank">
                {t('help.contact')}
              </Link>
            </li>
            <li>
              <Link to={SUPPORT_URL} target="_blank">
                {t('help.support')}
              </Link>
            </li>
            <li>
              <Link to={FAQ_URL} target="_blank">
                {t('help.faq')}
              </Link>
            </li>
          </ul>
        </SLRFooterItem>
        <SLRFooterItem title={t('project.title')} col={5}>
          <p>{t('project.description')}</p>
          <p>
            <Trans
              t={t}
              i18nKey="project.slr"
              components={[
                <Link
                  target="_blank"
                  to={SLR_WEBSITE_URL}
                  key="SLR_WEBSITE_URL"
                />
              ]}
            />
          </p>
          <Image
            src={bmel}
            alt={t('bmel')}
            loading="lazy"
            height="172"
            className="mw-100"
          />
        </SLRFooterItem>
      </Row>
    </SLRFooter>
  );
};

export default memo(Footer);
