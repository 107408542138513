/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  SLRPrompt,
  SLRPromptProps,
  SLRSpinner,
  showErrorToast,
  useDeleteMediaItem,
  useGetOrganizationMembershipInformation
} from '@SLR/shared-library';
import { ErrorView } from 'components';
import { ORGANIZATIONS_PATH, SHOW_ORGANIZATION_EDIT_PARAM } from 'configs';
import {
  useDeleteOrganization,
  useGetOrganization,
  useLeaveOrganization
} from 'hooks';
import { useQueryParam } from 'providers';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSessionStorage } from 'usehooks-ts';
import {
  InvitationsList,
  MembersList,
  OrganizationDescription,
  OrganizationHeader
} from '.';
import { KeyManagement, OrganizationModal } from '..';

export const OrganizationDetails: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'organizationsPage.details'
  });

  const { organizationId } = useParams();
  const navigate = useNavigate();
  const { solutionBarQuery, removeQueryParamsFromURL } = useQueryParam();
  const { showEncryption, showOrgEdit } = solutionBarQuery || {};

  const [hasKeyManagementModalAutoOpened, setHasKeyManagementModalAutoOpened] =
    useSessionStorage<boolean>('hasEncryptionModalAutoOpened', false);

  const {
    data: organization,
    isError,
    isSuccess: hasOrganizationLoaded
  } = useGetOrganization(organizationId ?? '');

  const { isAdmin, isMember } =
    useGetOrganizationMembershipInformation(organizationId);

  const leaveOrganization = useLeaveOrganization();
  const deleteOrganization = useDeleteOrganization();
  const deleteLogo = useDeleteMediaItem(() =>
    showErrorToast('toasts.deleteMediaItem.error')
  );

  const [showEditModal, setShowEditModal] = useState(false);
  const [showKeyManagement, setShowKeyManagement] = useState(false);
  const [SLRPromptData, setSLRPromptData] = useState<SLRPromptProps | null>(
    null
  );
  const hideSLRPrompt = () => setSLRPromptData(null);

  const handleKeyManagementClose = useCallback(
    () => setShowKeyManagement(false),
    []
  );

  const handleDelete = useCallback(() => {
    setSLRPromptData({
      confirmButtonVariant: 'danger',
      labels: t('delete', { returnObjects: true }),
      onConfirm: () => {
        if (organizationId) {
          deleteOrganization.mutate(
            { organizationId },
            {
              onSuccess: () => {
                if (organization?.logoId) {
                  deleteLogo.mutate([organization.logoId]);
                }
                navigate(ORGANIZATIONS_PATH);
              }
            }
          );
        }
        hideSLRPrompt();
      },
      onCancel: hideSLRPrompt
    });
  }, [
    t,
    deleteOrganization,
    organizationId,
    organization?.logoId,
    navigate,
    deleteLogo
  ]);

  const handleLeave = useCallback(() => {
    setSLRPromptData({
      confirmButtonVariant: 'danger',
      labels: t('leave', { returnObjects: true }),
      onConfirm: () => {
        if (organizationId) {
          leaveOrganization.mutate(
            { organizationId },
            {
              onSuccess: () => navigate(ORGANIZATIONS_PATH, { replace: true })
            }
          );
        }
        hideSLRPrompt();
      },
      onCancel: hideSLRPrompt
    });
  }, [leaveOrganization, navigate, organizationId, t]);

  const showInvitationList: boolean = useMemo(
    () => hasOrganizationLoaded && isAdmin,
    [hasOrganizationLoaded, isAdmin]
  );

  const handleOrganizationModalClose = useCallback(() => {
    setShowEditModal(false);
    removeQueryParamsFromURL(SHOW_ORGANIZATION_EDIT_PARAM, false);
  }, [removeQueryParamsFromURL]);

  useEffect(() => {
    if (isAdmin && showEncryption && !hasKeyManagementModalAutoOpened) {
      setShowKeyManagement(true);
      // This condition is to prevent the modal from opening again when the user navigates to organization details page
      setHasKeyManagementModalAutoOpened(true);
    }
    if (isAdmin && showOrgEdit) {
      setShowEditModal(true);
    }
  }, [
    hasKeyManagementModalAutoOpened,
    isAdmin,
    setHasKeyManagementModalAutoOpened,
    showEncryption,
    showOrgEdit
  ]);

  if (isError)
    return (
      <ErrorView
        errorMessage={t('noAccess')}
        showButton
        buttonType="organization"
        buttonPath={ORGANIZATIONS_PATH}
      />
    );

  if (organization)
    return (
      <>
        {SLRPromptData && <SLRPrompt {...SLRPromptData} />}
        {showEditModal && (
          <OrganizationModal
            onClose={handleOrganizationModalClose}
            organization={organization}
          />
        )}
        {showKeyManagement && (
          <KeyManagement
            onOpenEdit={() => setShowEditModal(true)}
            onClose={handleKeyManagementClose}
            organization={organization}
          />
        )}
        <OrganizationHeader
          organization={organization}
          isMember={isMember}
          isAdmin={isAdmin}
          onManageKey={() => setShowKeyManagement(true)}
          onEdit={() => setShowEditModal(true)}
          onDelete={handleDelete}
          onLeave={handleLeave}
        />

        <OrganizationDescription
          name={organization?.name}
          descriptionText={organization?.description}
          detailedDescription={organization?.detailedDescription}
        />
        {showInvitationList && (
          <InvitationsList organizationId={organizationId} isAdmin={isAdmin} />
        )}
        {hasOrganizationLoaded && (
          <MembersList organizationId={organizationId} isAdmin={isAdmin} />
        )}
      </>
    );

  return <SLRSpinner />;
};

export default OrganizationDetails;
