/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { Ownership } from '@SLR/media-service-sdk';
import {
  SLRFormInfo,
  SLRLogoUpload,
  SLRSpinner,
  getIcon,
  isEmptyOrNull,
  showErrorToast,
  useUpdateMediaItems
} from '@SLR/shared-library';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormFooter } from 'components';
import { useGetCurrentUserProfile, useUpdateUserProfile } from 'hooks';
import { FC, useCallback, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ProfileEmailFormField,
  ProfileInput,
  ProfileInputSchema,
  ProfileToasts
} from '..';

import './ProfileDetails.scss';

const ProfileDetails: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'profilePage.details'
  });

  const { data: user, isPending: isProfileLoading } =
    useGetCurrentUserProfile();

  const { mutate: updateProfile, isPending: isUpdateProfileLoading } =
    useUpdateUserProfile();

  const [isProfilePictureDialogBusy, setIsProfilePictureDialogBusy] =
    useState<boolean>(false);

  const { mutate: updateMedia } = useUpdateMediaItems({
    onDeleteError: () => showErrorToast('toasts.deleteMediaItem.error'),
    onSaveError: () => showErrorToast('toasts.saveMediaItem.error')
  });

  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    control,
    reset
  } = useForm<ProfileInput>({
    defaultValues: user,
    resolver: yupResolver(ProfileInputSchema())
  });

  useEffect(() => {
    if (user) reset(user);
  }, [user, reset]);

  const handleUpdateUserProfile = useCallback(
    (data: ProfileInput) => {
      const mediaIds = {
        newMediaIds: !isEmptyOrNull(data.pictureId) ? [data.pictureId] : [],
        oldMediaIds:
          user && !isEmptyOrNull(user?.pictureId) ? [user.pictureId] : []
      };

      updateProfile(
        // ts-ignore is needed to allow null values, as the SDK only allow undefined but in BE its defined as nullable
        // eslint-disable-next-line
        // @ts-ignore
        { updateUserProfileRequestDto: data },
        {
          onSuccess: () => updateMedia(mediaIds)
        }
      );
    },
    [updateMedia, updateProfile, user]
  );

  if (isProfileLoading) return <SLRSpinner />;

  return (
    <>
      <ProfileToasts />
      <Form
        onSubmit={handleSubmit(handleUpdateUserProfile)}
        noValidate
        className="profile-details"
      >
        <Row className="mb-5">
          <h1 className="mb-4">{t('title')}</h1>
          <Col>
            <Row className="flex-column flex-lg-row-reverse">
              <Col
                xs="auto"
                lg="6"
                xl="5"
                className="picture-editor-wrapper text-center"
              >
                <Form.Label htmlFor="picture" aria-hidden className="d-none">
                  {t('profilePicture')}
                </Form.Label>
                <SLRLogoUpload
                  id="picture"
                  name="pictureId"
                  control={control}
                  mediaOwner={{
                    owner: Ownership.User
                  }}
                  icons={{
                    fallbackIcon: getIcon('fal', 'image-slash'),
                    uploadIcon: getIcon('fal', 'cloud-upload'),
                    deleteIcon: getIcon('fal', 'trash-alt'),
                    rotateIcon: getIcon('fal', 'redo')
                  }}
                  labels={t('picture', {
                    returnObjects: true
                  })}
                  isBusy={setIsProfilePictureDialogBusy}
                />
              </Col>
              <Col xs="12" lg="6" xl="7" className="mt-4">
                <div>
                  <Form.Label htmlFor="firstName">{t('firstName')}*</Form.Label>
                  <Form.Control
                    id="firstName"
                    type="text"
                    className="form-control-lg"
                    isInvalid={!!errors.firstName}
                    {...register('firstName')}
                  />
                  <SLRFormInfo
                    text={errors?.firstName?.message}
                    isInvalid={!!errors.firstName}
                  />
                </div>
                <div className="mt-4">
                  <Form.Label htmlFor="lastName">{t('lastName')}*</Form.Label>
                  <Form.Control
                    id="lastName"
                    type="text"
                    className="form-control-lg"
                    isInvalid={!!errors.lastName}
                    {...register('lastName')}
                  />
                  <SLRFormInfo
                    text={errors?.lastName?.message}
                    isInvalid={!!errors.lastName}
                  />
                </div>
                <h3 className="mb-4 mt-6">{t('contact')}</h3>
                <ProfileEmailFormField
                  emailAddress={user?.emailAddress}
                  emailVerified={user?.emailVerified}
                />
                <div className="col-md-8 p-0 mt-4">
                  <Form.Label htmlFor="phoneNumber">
                    {t('phoneNumber')}
                  </Form.Label>
                  <Form.Control
                    id="phoneNumber"
                    type="text"
                    className="form-control-lg"
                    {...register('phoneNumber')}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <FormFooter
          isLoading={isUpdateProfileLoading}
          isDisabled={
            !isDirty || isProfileLoading || isProfilePictureDialogBusy
          }
        />
      </Form>
    </>
  );
};

export default ProfileDetails;
