/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  SLRLabelIcon,
  SLRModal,
  SLRPageHead,
  SLRSpinner,
  SLRTable,
  SLRToast,
  getIcon,
  hasValue
} from '@SLR/shared-library';
import { Invitation, UserMembershipInfo } from '@SLR/user-service-full-sdk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ORGANIZATIONS_PATH } from 'configs';
import {
  useAcceptCurrentUserPendingInvitation,
  useFindMembershipsOfUser,
  useGetPendingInvitationsOfCurrentUser,
  useRejectCurrentUserPendingInvitation
} from 'hooks';
import { FC, useCallback, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Row as TableRow } from 'react-table';
import {
  CreateOrganizationButton,
  EmptyOrganizations,
  OrganizationModal
} from '.';

import './OrganizationsPage.scss';

const OrganizationsPage: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'organizationsPage'
  });

  const navigate = useNavigate();

  const { data: organizations, isPending } = useFindMembershipsOfUser();

  const { data: pendingInvitations } = useGetPendingInvitationsOfCurrentUser();
  const { mutate: rejectInvitation } = useRejectCurrentUserPendingInvitation();
  const { mutate: acceptInvitation } = useAcceptCurrentUserPendingInvitation();

  const handleInvitationAccept = useCallback(
    (invitation: Invitation) => {
      acceptInvitation({
        invitationId: invitation.id,
        organizationId: invitation.organizationId
      });
    },
    [acceptInvitation]
  );

  const handleInvitationReject = useCallback(
    (invitation: Invitation) => {
      rejectInvitation({
        invitationId: invitation.id,
        organizationId: invitation.organizationId
      });
    },
    [rejectInvitation]
  );

  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const handleShowCreateModal = (show: boolean) => setShowCreateModal(show);

  const handleOpenOrganization = (row: TableRow) => {
    const organization = row.original as UserMembershipInfo;
    navigate(`${ORGANIZATIONS_PATH}/${organization?.organizationId}`);
  };

  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const handleShowInfoModal = (show: boolean) => setShowInfoModal(show);

  if (isPending) {
    return <SLRSpinner />;
  }

  return (
    <>
      {showCreateModal && (
        <OrganizationModal onClose={() => handleShowCreateModal(false)} />
      )}

      {showInfoModal && (
        <SLRModal
          isOpen
          size="lg"
          fullscreen="sm-down"
          className="organizations-page-info"
          title={t('info.title')}
          onClose={() => handleShowInfoModal(false)}
        >
          <h2 className="pb-3">{t('info.content.title')}</h2>

          <p>
            <Trans t={t} i18nKey="info.content.message" />
          </p>
        </SLRModal>
      )}

      <Container className="organizations-page">
        <SLRPageHead
          className="pb-5"
          labels={{
            title: (
              <div className="d-flex flex-row gap-3">
                {t('organizations')}
                <SLRLabelIcon
                  width="36"
                  icon={getIcon('fal', 'info-circle')}
                  onClick={() => handleShowInfoModal(true)}
                />
              </div>
            ),
            description: t('description')
          }}
        />
        {hasValue(pendingInvitations) && (
          <Row>
            <Col className="pb-5">
              {pendingInvitations.map((invitation) => (
                <SLRToast
                  key={invitation.id}
                  toastId={invitation.organizationName}
                  title={t('invitation.title', {
                    organizationName: invitation.organizationName
                  })}
                  type="info"
                  closeable={false}
                >
                  <>
                    {t('invitation.contentTop', {
                      organizationName: invitation.organizationName
                    })}
                    <br />
                    {t('invitation.contentBottom')}
                    <div className="mt-3 gap-3 d-flex flex-wrap">
                      <Button
                        variant="primary"
                        onClick={() => {
                          handleInvitationAccept(invitation);
                        }}
                      >
                        {t('invitation.accept')}
                      </Button>
                      <Button
                        variant="outline-danger"
                        onClick={() => {
                          handleInvitationReject(invitation);
                        }}
                      >
                        {t('invitation.reject')}
                      </Button>
                    </div>
                  </>
                </SLRToast>
              ))}
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12} className="table-responsive-md">
            {hasValue(organizations) ? (
              <SLRTable
                sortIcon={getIcon('fal', 'long-arrow-up')}
                tableHeaderProps={{
                  title: t('memberships'),
                  children: (
                    <CreateOrganizationButton
                      onClick={() => handleShowCreateModal(true)}
                    />
                  )
                }}
                columns={[
                  {
                    Header: t('organizations'),
                    accessor: 'organizationName'
                  },
                  {
                    Header: () => (
                      <>
                        <div className="d-none" aria-hidden>
                          {t('numberOfMembers')}
                        </div>
                        <FontAwesomeIcon
                          icon={getIcon('fal', 'users')}
                          aria-label={t('numberOfMembers')}
                        />
                      </>
                    ),
                    accessor: 'numberOfMembers'
                  },
                  {
                    Header: t('role'),
                    accessor: 'membershipRoles',
                    Cell: ({ value }: { value: 'admin' | 'member' }) =>
                      t(`${value}`)
                  }
                ]}
                data={organizations}
                onRowClick={handleOpenOrganization}
                responsive="md"
              />
            ) : (
              <EmptyOrganizations
                onCreate={() => handleShowCreateModal(true)}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OrganizationsPage;
