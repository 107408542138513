/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { OrganizationType } from '@SLR/user-service-full-sdk';
import {
  CHOOSE_ORGANIZATION_PATH,
  INVITATION_ACCEPT_IMMEDIATELY_PARAM,
  INVITATION_CHECK,
  INVITATION_HANDLE,
  INVITATION_TOKEN_PARAM,
  LEGAL_PATH,
  ORGANIZATIONS_PATH,
  ORGANIZATION_ID_PARAM,
  ORGANIZATION_NAME_PARAM,
  ORGANIZATION_TYPES_PARAM,
  PROFILE_PATH,
  REDIRECT_URL_PARAM,
  SHOW_ENCRYPTION_PARAM,
  SHOW_ORGANIZATION_EDIT_PARAM,
  SOLUTION_COLOR_PARAM,
  SOLUTION_NAME_PARAM,
  SOLUTION_URL_PARAM
} from 'configs';
import { t } from 'i18next';
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSessionStorage } from 'usehooks-ts';

export type SolutionBarQuery = {
  solutionURL?: string;
  solutionName?: string;
  solutionColor?: string;
  showEncryption?: boolean;
  showOrgEdit?: boolean;
};

export type ChooseOrganizationQuery = {
  redirectURL?: string;
  solutionName?: string;
  suggestedOrganizationName?: string;
  organizationType?: OrganizationType[];
};

export type InvitationQuery = {
  token?: string;
  organizationId?: string;
  acceptImmediately?: boolean;
};

type QueryContextParamsValues = {
  showSolutionBar?: boolean;
  showLayout?: boolean;
  solutionBarQuery?: SolutionBarQuery;
  chooseOrganizationQuery?: ChooseOrganizationQuery;
  invitationQuery?: InvitationQuery;
  removeQueryParamsFromURL: (
    param: string,
    resetValue: boolean | string
  ) => void;
};

const defaultQueryParamContextValues: QueryContextParamsValues = {
  showSolutionBar: false,
  showLayout: true,
  solutionBarQuery: {},
  chooseOrganizationQuery: {},
  invitationQuery: {},
  removeQueryParamsFromURL: () => {}
};

const QueryParamContext = createContext<QueryContextParamsValues>(
  defaultQueryParamContextValues
);

const useQueryParam = () => useContext(QueryParamContext);

const QueryParamProvider: FC<PropsWithChildren> = ({ children }) => {
  // #region Handling of query changes
  const [searchParams, setSearchParam] = useSearchParams();

  const [storedSolutionBarQuery, storeSolutionBarQuery] =
    useSessionStorage<SolutionBarQuery>('solutionBarQuery', {});

  useEffect(() => {
    const name = searchParams.get(SOLUTION_NAME_PARAM);
    const color = searchParams.get(SOLUTION_COLOR_PARAM);
    const encryption = searchParams.get(SHOW_ENCRYPTION_PARAM);
    const orgEdit = searchParams.get(SHOW_ORGANIZATION_EDIT_PARAM);

    storeSolutionBarQuery({
      solutionURL: searchParams.get(SOLUTION_URL_PARAM) ?? undefined,
      solutionName: name
        ? t('solutionBar.backToSolutionWithName', {
            solutionName: name
          })
        : t('solutionBar.backToSolutionWithoutName'),
      solutionColor: color ? '#' + color : undefined,
      showEncryption: encryption === '' || encryption === 'true',
      showOrgEdit: orgEdit === '' || orgEdit === 'true'
    });
    // eslint-disable-next-line
  }, []);

  const showSolutionBar = useMemo(
    () => !!storedSolutionBarQuery.solutionURL,
    [storedSolutionBarQuery]
  );

  const solutionBarQuery = useMemo(
    () => storedSolutionBarQuery,
    [storedSolutionBarQuery]
  );

  const chooseOrganizationQuery: ChooseOrganizationQuery = useMemo(
    () => ({
      redirectURL: searchParams.get(REDIRECT_URL_PARAM) ?? undefined,
      solutionName: searchParams.get(SOLUTION_NAME_PARAM) ?? undefined,
      suggestedOrganizationName:
        searchParams.get(ORGANIZATION_NAME_PARAM) ?? undefined,
      organizationType: Object.values(OrganizationType).filter((type) =>
        searchParams.getAll(ORGANIZATION_TYPES_PARAM)?.includes(type)
      )
    }),
    [searchParams]
  );

  const invitationQuery: InvitationQuery = useMemo(
    () => ({
      token: searchParams.get(INVITATION_TOKEN_PARAM) ?? undefined,
      organizationId: searchParams.get(ORGANIZATION_ID_PARAM) ?? undefined,
      acceptImmediately:
        searchParams.get(INVITATION_ACCEPT_IMMEDIATELY_PARAM) === 'true'
    }),
    [searchParams]
  );

  const removeQueryParamsFromURL = useCallback(
    (param: string, resetValue: string | boolean) => {
      setSearchParam((prevParams) => {
        if (prevParams.has(param)) prevParams.delete(param);

        return prevParams;
      });
      storeSolutionBarQuery((prevSolutionBarQuery) => ({
        ...prevSolutionBarQuery,
        [param]: resetValue
      }));
    },
    [setSearchParam, storeSolutionBarQuery]
  );
  // #endregion

  // #region Handling of pathname changes
  const { pathname } = useLocation();

  // Update page title  when path is changed
  const startPage = useMemo(() => {
    const appTitle = t('appName');
    if (pathname.includes(PROFILE_PATH)) {
      return `${appTitle} - ${t('profile')}`;
    } else if (
      pathname.includes(ORGANIZATIONS_PATH) ||
      pathname.includes(CHOOSE_ORGANIZATION_PATH)
    ) {
      return `${appTitle} - ${t('organizations')}`;
    } else if (
      pathname.includes(INVITATION_CHECK) ||
      pathname.includes(INVITATION_HANDLE)
    ) {
      return `${appTitle} - ${t('invitations')}`;
    } else if (pathname.includes(LEGAL_PATH)) {
      return `${appTitle} - ${t('legal')}`;
    } else return appTitle;
  }, [pathname]);
  // #endregion

  // #region Layout visibility
  const showLayout = useMemo(
    () =>
      !(
        pathname.includes(CHOOSE_ORGANIZATION_PATH) ||
        pathname.includes(INVITATION_CHECK) ||
        pathname.includes(INVITATION_HANDLE)
      ),
    [pathname]
  );
  // #endregion

  const queryContextValues: QueryContextParamsValues = useMemo(
    () => ({
      showSolutionBar,
      showLayout,
      solutionBarQuery,
      chooseOrganizationQuery,
      invitationQuery,
      removeQueryParamsFromURL
    }),
    [
      showSolutionBar,
      showLayout,
      solutionBarQuery,
      chooseOrganizationQuery,
      invitationQuery,
      removeQueryParamsFromURL
    ]
  );

  return (
    <QueryParamContext.Provider value={queryContextValues}>
      <Helmet>
        <title>{startPage}</title>
      </Helmet>

      {children}
    </QueryParamContext.Provider>
  );
};

export { QueryParamProvider, useQueryParam };
