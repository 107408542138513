/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { ErrorLogo } from 'assets';
import { LegalLinks } from 'components';
import { CONTACT_URL } from 'configs';
import { FC, useMemo } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import './ErrorView.scss';

type ErrorViewProps = {
  errorMessage?: string;
  showContact?: boolean;
  showButton?: boolean;
  buttonType?: 'home' | 'organization';
  buttonPath?: string;
  isModal?: boolean;
};

const ErrorView: FC<ErrorViewProps> = ({
  errorMessage,
  showContact = true,
  showButton = false,
  buttonType = 'home',
  buttonPath = '/',
  isModal = false
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const buttonText = useMemo(
    () =>
      buttonType === 'home' ? t('error.toHome') : t('error.toOrganizations'),
    [buttonType, t]
  );

  return (
    <Container className="error-view">
      <Row className="text-center my-5 d-flex flex-column justify-content-center align-items-center bg-white gap-4">
        <Col xs="12">
          {isModal ? <h2>{t('error.title')}</h2> : <h1>{t('error.title')}</h1>}
        </Col>
        <Col xs="12">
          <p className={isModal ? '' : 'lead'}>
            {errorMessage ?? t('error.noResponse')}
          </p>
        </Col>
        <Col xs="12" className="error-divider" />
        <Col xs="12">
          <ErrorLogo width="100%" />
        </Col>
        {showContact && (
          <Col xs="12">
            <p>
              <Trans
                t={t}
                i18nKey="error.options"
                components={[
                  <Link target="_blank" to={CONTACT_URL} key={CONTACT_URL} />
                ]}
              />
            </p>
          </Col>
        )}
        {showButton && (
          <Col>
            <Button
              size="lg"
              variant="primary"
              onClick={() => navigate(buttonPath)}
            >
              {buttonText}
            </Button>
          </Col>
        )}
      </Row>
    </Container>
  );
};

const ErrorModal: FC<ErrorViewProps> = (props) => (
  <Modal show centered backdrop={false}>
    <Modal.Body>
      <ErrorView {...props} isModal />
    </Modal.Body>
    <LegalLinks />
  </Modal>
);

export { ErrorModal, ErrorView };
