/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { getIcon } from '@SLR/shared-library';
import { Organization } from '@SLR/user-service-full-sdk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ExistingOrganizationList from './organizations/ExistingOrganizationList';

import './SelectExistingOrganization.scss';

type SelectExistingOrganizationProps = {
  solutionName: string;
  organizationName?: string;
  organizations: Organization[] | undefined;
  onCreate: () => void;
  onAdd: (id: string) => void;
};

const SelectExistingOrganization: FC<SelectExistingOrganizationProps> = ({
  solutionName,
  organizationName,
  organizations,
  onCreate,
  onAdd
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chooseOrganizationPage.select'
  });

  const [selectedOrganizationId, setSelectedOrganizationId] = useState('');

  return (
    <Modal.Body className="select-existing-organization px-5 pb-4">
      <Row>
        <Col className="mb-4 text-center description">
          {t('description', { solution: solutionName })}
        </Col>
      </Row>

      <ExistingOrganizationList
        organizations={organizations}
        onSelect={setSelectedOrganizationId}
        selectedOrganizationId={selectedOrganizationId}
        defaultSearchValue={organizationName}
      />
      <Row>
        <Col className="text-center mt-2">
          <Button variant="link" onClick={onCreate} size="lg">
            <FontAwesomeIcon icon={getIcon('fal', 'plus')} className="me-2" />
            {t('create')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="text-center mt-3">
          <Button
            size="lg"
            variant="primary"
            disabled={!selectedOrganizationId}
            onClick={() => onAdd(selectedOrganizationId)}
          >
            {t('add', {
              solution: solutionName
            })}
          </Button>
        </Col>
      </Row>
    </Modal.Body>
  );
};

export default SelectExistingOrganization;
