/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { chooseOrganizationPage } from './chooseOrganizationPage';
import { invitationsPage } from './invitationsPage';
import { organizationsPage } from './organizationsPage';
import { profilePage } from './profilePage';
import { toasts } from './toasts';

export const de = {
  appName: 'DEUTSCHLAND.DIGITAL-Konto',
  profile: 'Profil',
  organizations: 'Organisationen',
  invitations: 'Einladungen',
  legal: 'Rechtliches',
  email: 'E-Mail',
  solutionBar: {
    backToSolutionWithName: 'Zurück zu "{{solutionName}}"',
    backToSolutionWithoutName: 'Zurück zur Lösung'
  },
  navBar: {
    logout: 'Abmelden'
  },
  error: {
    title: 'Hoppla, etwas lief schief!',
    noResponse: 'Der Server reagiert nicht.\nBitte versuch es später erneut.',
    options:
      'Wir schauen uns das Problem umgehend an. Falls du Hilfe benötigst oder Fragen hast, wende dich gerne ans <0>DEUTSCHLAND.DIGITAL Support Team</0>!',
    toHome: 'Zur Startseite',
    toLandingPage: 'Zu DEUTSCHLAND.DIGITAL',
    toOrganizations: 'Zur Organisationen'
  },
  form: {
    apply: 'Übernehmen',
    mandatoryField: '*Pflichtfeld',
    counter: '{{count}} / {{maxCount}} Zeichen',
    maxCharLengthError:
      'Maximale Zeichenlänge überschritten. Bitte kürze den Text.'
  },
  accessibility: {
    appURLLabel: 'Zurück zur Profilseite',
    navigateToLabel: 'Navigiere zur URL:',
    navigateBackLabel: 'Zurück zur vorherigen Seite',
    fallbackImageAlt: 'Platzhalter Bild (Original konnte nicht geladen werden)',
    profilePictureAlt: 'Profilbild',
    accordion: {
      deleteButtonLabel: 'Lösch-Taste',
      dragButtonLabel: 'Ziehen-Taste'
    },
    menuToggleLabel: 'Optionen'
  },
  footer: {
    links: {
      title: 'Nützliche Links',
      privacyPolicy: 'Datenschutzhinweise',
      termsOfUse: 'Allgemeine Geschäftsbedingungen',
      imprint: 'Impressum'
    },
    help: {
      title: 'Fragen & Hilfe',
      contactUs: 'Einfach das Kontaktformular verwenden.',
      lookingForward: 'Wir freuen uns schon!',
      contact: 'Kontakt',
      support: 'Support',
      faq: 'FAQ'
    },
    project: {
      title: 'Das Projekt',
      description:
        '“DEUTSCHLAND.DIGITAL-Konto” wird vom Fraunhofer IESE entwickelt und betrieben. Das Fraunhofer IESE gehört zu den weltweit führenden Forschungseinrichtungen auf dem Gebiet der Software- und Systementwicklungsmethoden.',
      slr: ' Es ist Teil des <0>Projektes Smarte.Land.Regionen</0>. Mit dem Modellvorhaben unterstützt das Bundesministerium für Ernährung und Landwirtschaft (BMEL) sieben einzelne Landkreise bei der Entwicklung und Umsetzung digitaler Lösungen im ländlichen Raum.'
    },
    bmel: 'Förderhinweis Logo'
  },
  toasts: {
    ...toasts
  },
  profilePage: {
    ...profilePage
  },
  organizationsPage: {
    ...organizationsPage
  },
  chooseOrganizationPage: {
    ...chooseOrganizationPage
  },
  invitationsPage: {
    ...invitationsPage
  }
};
