/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRSpinner } from '@SLR/shared-library';
import { ErrorModal } from 'components';
import { useGetOrganizationsOfCurrentPerson } from 'hooks';
import { OrganizationModal } from 'pages/organizations';
import { useQueryParam } from 'providers';
import { FC, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  ChooseOrganizationModal,
  CreateNewOrganization,
  SelectExistingOrganization
} from '.';

import './ChooseOrganizationPage.scss';

const ChooseOrganizationPage: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'chooseOrganizationPage'
  });

  const { chooseOrganizationQuery } = useQueryParam();
  const {
    redirectURL,
    solutionName,
    suggestedOrganizationName,
    organizationType
  } = chooseOrganizationQuery || {};

  const { data: organizations, isPending } = useGetOrganizationsOfCurrentPerson(
    organizationType?.join(',')
  );

  const redirect = (id: string) =>
    // Check if redirect url contains query parameters
    redirectURL?.includes('?')
      ? window.location.replace(`${redirectURL}&organizationId=${id}`)
      : window.location.replace(`${redirectURL}?organizationId=${id}`);

  const handleOrganizationSelection = (organizationId: string) => {
    redirect(organizationId);
  };

  const [showCreateModal, setShowCreateModal] = useState(false);

  const showCreateOrganizationModal =
    organizations === undefined ||
    (organizations.length === 0 && !showCreateModal);

  const showChooseOrganizationModal =
    organizations !== undefined && organizations.length > 0;

  return (
    <Container className="add-organization-page" fluid>
      {isPending ? (
        <SLRSpinner variant="light" />
      ) : !redirectURL || !solutionName ? (
        <ErrorModal errorMessage={t('error')} showContact={false} />
      ) : (
        <>
          {showCreateOrganizationModal && (
            <ChooseOrganizationModal
              title={t('create.title')}
              solutionName={solutionName}
              solutionUrl={redirectURL}
            >
              <CreateNewOrganization
                onCreate={() => setShowCreateModal(true)}
              />
            </ChooseOrganizationModal>
          )}
          {showChooseOrganizationModal && (
            <ChooseOrganizationModal
              title={t('select.title')}
              solutionName={solutionName}
              solutionUrl={redirectURL}
            >
              <SelectExistingOrganization
                onCreate={() => setShowCreateModal(true)}
                onAdd={handleOrganizationSelection}
                organizationName={suggestedOrganizationName}
                organizations={organizations}
                solutionName={solutionName}
              />
            </ChooseOrganizationModal>
          )}

          {showCreateModal && (
            <OrganizationModal
              onCreate={redirect}
              suggestedOrganizationName={suggestedOrganizationName}
              buttonLabel={t('createAndAdd', {
                solution: solutionName
              })}
              onClose={() => setShowCreateModal(false)}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default ChooseOrganizationPage;
