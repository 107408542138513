/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRLabelIcon, SLRMenu, getIcon } from '@SLR/shared-library';
import { FC, ReactElement, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type OrganizationDropdownProps = {
  isUserOrganization?: boolean;
  showEncryption: boolean;
  isMember?: boolean;
  isAdmin?: boolean;
  onEdit: () => void;
  onManageKey: () => void;
  onDelete: () => void;
  onLeave: () => void;
};

const OrganizationDropdown: FC<OrganizationDropdownProps> = ({
  isUserOrganization = false,
  showEncryption = false,
  isMember = false,
  isAdmin = false,
  onManageKey,
  onEdit,
  onLeave,
  onDelete
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'organizationsPage.details.options'
  });

  const allowedActions: ReactElement[] = useMemo(() => {
    const actions = [];

    if (isAdmin) {
      actions.push(
        <SLRLabelIcon
          icon={getIcon('fal', 'pencil')}
          label={t('edit')}
          onClick={onEdit}
        />
      );
      if (showEncryption) {
        actions.push(
          <SLRLabelIcon
            icon={getIcon('fal', 'key')}
            label={t('manageKey')}
            onClick={onManageKey}
          />
        );
      }
    }

    if (isAdmin && isUserOrganization) {
      actions.push(
        <SLRLabelIcon
          className="text-danger"
          icon={getIcon('fal', 'trash-can')}
          label={t('delete')}
          onClick={onDelete}
        />
      );
    }

    actions.push(
      <SLRLabelIcon
        className="text-danger"
        icon={getIcon('fal', 'user-slash')}
        label={t('leave')}
        onClick={onLeave}
      />
    );
    return actions;
  }, [
    isAdmin,
    isUserOrganization,
    onDelete,
    onEdit,
    onLeave,
    onManageKey,
    showEncryption,
    t
  ]);

  return (
    isMember && (
      <SLRMenu
        toggleLabel={t('menuLabel')}
        toggleIcon={getIcon('fal', 'ellipsis-stroke')}
        options={allowedActions}
      />
    )
  );
};

export default memo(OrganizationDropdown);
