/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { InvitationSuccessLogo } from 'assets';
import { ORGANIZATIONS_PATH } from 'configs';
import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type SuccessProps = {
  organizationName: string;
  organizationId: string;
};

const Success: FC<SuccessProps> = ({ organizationName, organizationId }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'invitationsPage.success'
  });

  return (
    <>
      <Row>
        <Col>
          <p className="text-left">
            {t('description', {
              organization: organizationName
            })}
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="text-left mt-4">{t('options')}</p>
        </Col>
      </Row>
      <Row>
        <Col className="text-center mt-5">
          <InvitationSuccessLogo />
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <Link
            to={`${ORGANIZATIONS_PATH}/${organizationId}`}
            className="mt-5 mb-4 btn btn-primary btn-lg"
            replace
          >
            {t('manage')}
          </Link>
        </Col>
      </Row>
    </>
  );
};

export default Success;
