/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

export const toasts = {
  save: {
    success: { title: 'Erfolgreich übernommen!' }
  },
  passwordReset: {
    success: {
      title: 'E-Mail verschickt!',
      content:
        'Wir haben eine E-Mail mit einer Anleitung an {{email}} geschickt. Falls du die Nachricht nicht erhalten hast, prüfe bitte deinen Spam-Ordner oder lass dir eine neue E-Mail schicken.'
    },
    error: {
      title: 'Hoppla, etwas lief schief!',
      content:
        'Die E-Mail konnte nicht verschickt werden. Bitte versuche es später erneut.'
    }
  },
  deactivateSecurity: {
    success: {
      title: '2FA deaktiviert',
      content: 'Jetzt können Sie sich ohne zusätzlichen Code anmelden.'
    }
  },
  sendVerificationEmail: {
    success: { title: 'E-Mail verschickt!' }
  },
  emailChangeCancel: {
    success: {
      title: 'Änderung der E-Mail-Adresse erfolgreich abgebrochen.'
    }
  },
  emailChangeResend: {
    success: { title: 'E-Mail erneut versendet.' }
  },
  emailChange: {
    invalidCredentials: 'Bitte überprüfe dein Passwort und versuche es erneut.',
    invalidCredentialsOrOtp:
      'Bitte überprüfe dein Passwort oder Sicherheitscode und versuche es erneut.',
    error: {
      title: 'Hoppla, etwas lief schief!',
      content:
        'Die Adresse {{email}} wird bereits verwendet. Bitte versuche es mit einer anderen E-Mail Adresse'
    }
  },
  requestDataReport: {
    success: {
      title: 'Datenbericht angefordert',
      content: 'Du erhälst innerhalb von 48 Stunden eine E-Mail von uns!'
    }
  },
  createOrganization: {
    success: { title: 'Organisation wurde erstellt.' }
  },
  leaveOrganization: {
    success: { title: 'Organisation wurde verlassen.' }
  },
  deleteOrganization: {
    success: { title: 'Erfolgreich gelöscht!' }
  },
  loadOrganizationMembers: {
    error: {
      title: 'Hoppla, etwas lief schief!',
      content:
        'Leider ist beim Laden der Mitgliederdatenein ein Fehler aufgetreten.'
    }
  },
  updateMemberRole: {
    success: { title: 'Rolle wurde geändert.' },
    error: { title: 'Rolle wurde nicht geändert!' }
  },
  removeMember: {
    success: { title: 'Mitglied wurde entfernt.' },
    error: { title: 'Mitglied wurde nicht entfernt!' }
  },
  resendInvitation: {
    success: {
      title: 'Einladung wurde verschickt.',
      content: 'Die E-Mail mit dem Einladungslink wird in Kürze eintreffen.'
    },
    warning: {
      title: 'Einladung nicht verschickt!',
      content:
        'Die vorherige Einladung ist noch gültig. Für eine neue Einladung versuche es bitte später erneut.'
    },
    error: {
      title: 'Hoppla, etwas lief schief!',
      content:
        'Die Einladung konnte nicht verschickt werden. Bitte versuche es später erneut.'
    }
  },
  loadInvitations: {
    error: {
      title: 'Hoppla, etwas lief schief!',
      content:
        'Leider ist beim Laden der Einladungsdaten ein Fehler aufgetreten.'
    }
  },
  createInvitation: {
    success: {
      title: 'Einladung wurde verschickt.',
      content:
        'Die Person mit der E-Mail-Adresse {{email}} wurde erfolgreich zur Organisation eingeladen.'
    },
    error: {
      title: 'Einladung nicht verschickt!',
      alreadyMember:
        'Die Person mit der E-Mail-Adresse {{email}} ist bereits Mitglied der Organisation.',
      alreadyInvited:
        'Die Person mit der E-Mail-Adresse {{email}} wurde bereits zur Organisation eingeladen. Bitte die Person, die Einladung unter {{url}} anzunehmen.'
    }
  },
  updateInvitationRole: {
    success: { title: 'Rolle wurde geändert.' },
    error: { title: 'Rolle nicht geändert.' }
  },
  revokeInvitation: {
    success: { title: 'Einladung wurde zurückgezogen.' },
    error: { title: 'Einladung nicht zurückgezogen!' }
  },
  acceptPendingInvitation: {
    error: {
      title: 'Einladung konnte nicht angenommen werden!',
      content: 'Die Einladung ist leider nicht mehr gültig.'
    }
  },
  rejectPendingInvitation: {
    error: {
      title: 'Einladung konnte nicht abgelehnt werden!',
      content: 'Die Einladung ist leider nicht mehr gültig.'
    }
  },
  uploadMediaItem: {
    error: {
      title: 'Bild konnte nicht hochgeladen werden!',
      content: 'Bitte versuch es zu einem späteren Zeitpunkt erneut.'
    }
  },
  saveMediaItem: {
    error: {
      title: 'Bild konnte nicht gespeichert werden!',
      content: 'Bitte versuch es zu einem späteren Zeitpunkt erneut.'
    }
  },
  deleteMediaItem: {
    error: {
      title: 'Bild konnte nicht gelöscht werden!',
      content: 'Bitte versuch es zu einem späteren Zeitpunkt erneut.'
    }
  },
  unexpected: {
    error: {
      title: 'Hoppla, etwas lief schief!',
      content: 'Bitte versuch es zu einem späteren Zeitpunkt erneut.'
    }
  },
  createOrganizationKey: {
    missingEmailError: {
      title: 'Organisations-E-Mail fehlt!',
      content:
        'Eine Organisations-E-Mail wird benötigt, um Schlüssel zu generieren. Bitte füge deine Organisations-E-Mail zur Organisation hinzu.'
    },
    invalidEmailError: {
      title: 'Organisations-E-Mail ist ungültig!',
      content:
        'Für die Generierung von Schlüsseln wird eine gültige E-Mail benötigt. Bitte überprüfe deine Organisations-E-Mail.'
    }
  }
};
