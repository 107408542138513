/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRSideBar, SLRSideBarItem, useWindowSize } from '@SLR/shared-library';
import {
  MOBILE_VIEW_WIDTH,
  PROFILE_DATA_PRIVACY_PATH,
  PROFILE_EMAIL_UPDATE_PATH,
  PROFILE_PASSWORD_UPDATE_PATH,
  PROFILE_PATH,
  PROFILE_SECURITY_PATH
} from 'configs';
import { FC, PropsWithChildren, memo, useMemo } from 'react';
import { ButtonGroup, Container, Dropdown, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import './ProfilePage.scss';

const ProfilePage: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'profilePage'
  });

  const { isMobileView } = useWindowSize(MOBILE_VIEW_WIDTH);
  const { pathname } = useLocation();

  const navItems = useMemo(
    () => [
      {
        key: PROFILE_PATH,
        to: PROFILE_PATH,
        label: t('pages.yourProfile')
      },
      {
        key: PROFILE_EMAIL_UPDATE_PATH,
        to: `${PROFILE_PATH}/${PROFILE_EMAIL_UPDATE_PATH}`,
        label: t('pages.changeEmail')
      },
      {
        key: PROFILE_PASSWORD_UPDATE_PATH,
        to: `${PROFILE_PATH}/${PROFILE_PASSWORD_UPDATE_PATH}`,
        label: t('pages.changePassword')
      },
      {
        key: PROFILE_SECURITY_PATH,
        to: `${PROFILE_PATH}/${PROFILE_SECURITY_PATH}`,
        label: t('pages.security')
      },
      {
        key: PROFILE_DATA_PRIVACY_PATH,
        to: `${PROFILE_PATH}/${PROFILE_DATA_PRIVACY_PATH}`,
        label: t('pages.dataPrivacy')
      }
    ],
    [t]
  );

  const title = useMemo(
    () => navItems.find((item) => pathname === item.to)?.label ?? '',
    [navItems, pathname]
  );

  return (
    <div className="profile-page">
      {isMobileView ? (
        <div className="p-4">
          <Dropdown as={ButtonGroup} className="profile-drop-down">
            <Dropdown.Toggle
              variant="outline-primary"
              className="mobile-dropdown-toggle"
            >
              {title}
            </Dropdown.Toggle>
            <Dropdown.Toggle
              split
              variant="outline-primary"
              size="lg"
            ></Dropdown.Toggle>
            <Dropdown.Menu>
              {navItems.map((item) => (
                <NavDropdown.Item as={Link} key={item.key} to={item.to}>
                  {item.label}
                </NavDropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {children}
        </div>
      ) : (
        <Container className="py-6">
          <SLRSideBar
            items={navItems.map((item) => (
              <SLRSideBarItem key={item.key} to={item.to} label={item.label} />
            ))}
            title={t('title')}
          >
            {children}
          </SLRSideBar>
        </Container>
      )}
    </div>
  );
};

export default memo(ProfilePage);
