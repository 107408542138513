/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  SLRAuthProvider,
  SLRFormProvider,
  SLRLegalRouting,
  SLRLibraryProvider,
  SLRProtectedRoute,
  SLRSpinner,
  SLRToastProvider,
  useAuth
} from '@SLR/shared-library';
import 'assets/icons';
import {
  CHOOSE_ORGANIZATION_PATH,
  DELETED_PROFILE_PATH,
  INVITATION_CHECK,
  INVITATION_HANDLE,
  LEGAL_PATH,
  ORGANIZATIONS_PATH,
  PROFILE_PATH,
  authConfiguration,
  formConfiguration,
  legalRouts,
  libraryConfiguration,
  toastConfiguration
} from 'configs';
import { Layout } from 'layout';
import 'localization';
import {
  ChooseOrganizationPage,
  DeletedProfile,
  InvitationCheckPage,
  InvitationHandlePage,
  OrganizationsRouting,
  ProfileRouting
} from 'pages';
import { QueryParamProvider } from 'providers';
import React, { FC, useCallback } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './theme/index.scss';

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = createRoot(container);

const UserPortal: FC = () => {
  const { isAuthenticated, login } = useAuth();

  const handleLogin = useCallback(() => login(window.location.href), [login]);

  return (
    <Layout>
      <Routes>
        <Route
          element={
            <SLRProtectedRoute
              condition={isAuthenticated}
              callback={handleLogin}
              callbackSpinner={<SLRSpinner />}
            />
          }
        >
          <Route index element={<Navigate to={PROFILE_PATH} replace />} />
          <Route path={`${PROFILE_PATH}/*`} element={<ProfileRouting />} />
          <Route
            path={`${ORGANIZATIONS_PATH}/*`}
            element={<OrganizationsRouting />}
          />
          <Route
            path={CHOOSE_ORGANIZATION_PATH}
            element={<ChooseOrganizationPage />}
          />
          <Route path={INVITATION_HANDLE} element={<InvitationHandlePage />} />
        </Route>
        <Route path={INVITATION_CHECK} element={<InvitationCheckPage />} />
        <Route path={DELETED_PROFILE_PATH} element={<DeletedProfile />} />
        <Route
          path={`${LEGAL_PATH}/*`}
          element={
            <SLRLegalRouting legals={legalRouts} redirect={PROFILE_PATH} />
          }
        />
        <Route path="*" element={<Navigate to={PROFILE_PATH} replace />} />
      </Routes>
    </Layout>
  );
};

root.render(
  <SLRAuthProvider {...authConfiguration}>
    <React.StrictMode>
      <BrowserRouter>
        <SLRLibraryProvider {...libraryConfiguration}>
          <SLRToastProvider {...toastConfiguration}>
            <SLRFormProvider {...formConfiguration}>
              <HelmetProvider>
                <QueryParamProvider>
                  <UserPortal />
                </QueryParamProvider>
              </HelmetProvider>
            </SLRFormProvider>
          </SLRToastProvider>
        </SLRLibraryProvider>
      </BrowserRouter>
    </React.StrictMode>
  </SLRAuthProvider>
);
