/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRToast } from '@SLR/shared-library';
import {
  useCancelChangeEmailAddress,
  useGetCurrentUserProfile,
  useResendChangeEmailAddressVerificationEmail,
  useSendVerificationEmail
} from 'hooks';
import { t } from 'i18next';
import { FC, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { Trans } from 'react-i18next';

const ProfileToasts: FC = () => {
  const { data: user } = useGetCurrentUserProfile();

  const { mutate: sendVerificationEmail } = useSendVerificationEmail();
  const { mutate: resendVerificationEmail } =
    useResendChangeEmailAddressVerificationEmail();
  const { mutate: cancelChangeEmailAddress } = useCancelChangeEmailAddress();

  const handleSend = useCallback(
    () => sendVerificationEmail(),
    [sendVerificationEmail]
  );
  const handleCancel = useCallback(
    () => cancelChangeEmailAddress(),
    [cancelChangeEmailAddress]
  );
  const handleResend = useCallback(
    () => resendVerificationEmail(),
    [resendVerificationEmail]
  );

  return (
    <>
      {(!user?.emailVerified || user?.pendingNewEmailAddress) && (
        <div className="pb-5">
          {!user?.emailVerified && (
            <SLRToast
              toastId="confirm-email"
              title={t('profilePage.alerts.email.confirm.title')}
              type="info"
              closeable={false}
            >
              <>
                <Trans
                  t={t}
                  key="confirm-email"
                  i18nKey="profilePage.alerts.email.confirm.text"
                  values={{ email: user?.emailAddress }}
                />
                <div className="mt-3">
                  <Button variant="primary" onClick={handleSend}>
                    {t('profilePage.alerts.email.resend')}
                  </Button>
                </div>
              </>
            </SLRToast>
          )}

          {user?.pendingNewEmailAddress && (
            <SLRToast
              toastId="confirm-email"
              title={t('profilePage.alerts.email.new.title')}
              type="info"
              closeable={false}
            >
              <>
                <Trans
                  t={t}
                  key="new-email"
                  i18nKey="profilePage.alerts.email.new.text"
                  values={{ email: user?.pendingNewEmailAddress }}
                />
                <div className="mt-3 gap-3 d-flex flex-wrap">
                  <Button variant="primary" onClick={handleResend}>
                    {t('profilePage.alerts.email.resend')}
                  </Button>

                  <Button
                    variant="outline-info"
                    className="cancel-button"
                    onClick={() => {
                      handleCancel();
                    }}
                  >
                    {t('profilePage.alerts.email.cancel')}
                  </Button>
                </div>
              </>
            </SLRToast>
          )}
        </div>
      )}
    </>
  );
};

export default ProfileToasts;
