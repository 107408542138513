/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer, Prajwala Adiga
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRToast } from '@SLR/shared-library';
import { FC } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type KeyManagementToastsProps = {
  onOpenEdit: () => void;
  isMissingEmail: boolean;
  isCreated: boolean;
  isUpdated: boolean;
  isExpired: boolean;
};

const KeyManagementToasts: FC<KeyManagementToastsProps> = ({
  onOpenEdit,
  isMissingEmail,
  isCreated,
  isUpdated,
  isExpired
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'organizationsPage.details.keyManagement.toast'
  });

  return (
    <>
      {(isMissingEmail || isCreated || isUpdated || isExpired) && (
        <div className="pt-4 pb-2">
          {isMissingEmail && (
            <SLRToast
              toastId="missing-email"
              title={t('email.title')}
              type="warning"
              closeable={false}
            >
              <Row>
                <Col>{t('email.content')}</Col>
                <Col xs="12">
                  <Button className="mt-3" onClick={onOpenEdit}>
                    {t('email.button')}
                  </Button>
                </Col>
              </Row>
            </SLRToast>
          )}
          {isCreated && (
            <SLRToast
              toastId="created"
              title={t('newKeyGenerated.title')}
              type="warning"
              closeable={false}
            >
              {t('newKeyGenerated.content')}
            </SLRToast>
          )}
          {isUpdated && (
            <SLRToast
              toastId="updated"
              title={t('oldKeyRefreshed.title')}
              type="warning"
              closeable={false}
            >
              {t('oldKeyRefreshed.content')}
            </SLRToast>
          )}
          {isExpired && (
            <SLRToast
              toastId="expired"
              title={t('keyExpired.title')}
              type="warning"
              closeable={false}
            >
              {t('keyExpired.content')}
            </SLRToast>
          )}
        </div>
      )}
    </>
  );
};

export default KeyManagementToasts;
