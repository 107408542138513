/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { handleError } from '@SLR/shared-library';
import {
  ChangeEmailAddressRequest,
  LeaveOrganizationRequest,
  SendUpdatePasswordEmailRequest,
  UpdateUserProfileRequest,
  UsersApi
} from '@SLR/user-service-full-sdk';
import { useCallback, useMemo } from 'react';
import { useGetUserServiceApiConfiguration } from './user.service';

const useUsersApi = () => {
  const configuration = useGetUserServiceApiConfiguration();
  const apiInstance = useMemo(
    () => new UsersApi(configuration),
    [configuration]
  );

  const getCurrentUserProfile = useCallback(
    () => apiInstance.getUserProfileOfCurrentUser().catch(handleError),
    [apiInstance]
  );

  const updateUserProfile = useCallback(
    (requestParameters: UpdateUserProfileRequest) =>
      apiInstance.updateUserProfile(requestParameters).catch(handleError),
    [apiInstance]
  );

  const changeEmailAddress = useCallback(
    (requestParameters: ChangeEmailAddressRequest) =>
      apiInstance.changeEmailAddress(requestParameters).catch(handleError),
    [apiInstance]
  );

  const cancelChangeEmailAddress = useCallback(
    () => apiInstance.cancelChangeEmailAddress().catch(handleError),
    [apiInstance]
  );

  const sendVerificationEmail = useCallback(
    () => apiInstance.sendVerificationEmail().catch(handleError),
    [apiInstance]
  );

  const resendChangeEmailAddressVerificationEmail = useCallback(
    () =>
      apiInstance
        .resendChangeEmailAddressVerificationEmail()
        .catch(handleError),
    [apiInstance]
  );

  const sendUpdatePasswordEmail = useCallback(
    (requestParameters: SendUpdatePasswordEmailRequest) =>
      apiInstance.sendUpdatePasswordEmail(requestParameters).catch(handleError),
    [apiInstance]
  );

  const leaveOrganization = useCallback(
    (requestParameters: LeaveOrganizationRequest) =>
      apiInstance.leaveOrganization(requestParameters).catch(handleError),
    [apiInstance]
  );

  const hasCurrentUserOtpCredentials = useCallback(
    () => apiInstance.hasCurrentUserOtpCredentials().catch(handleError),
    [apiInstance]
  );

  const disableOtpForCurrentUser = useCallback(
    () => apiInstance.disableOtpForCurrentUser().catch(handleError),
    [apiInstance]
  );

  return {
    getCurrentUserProfile,
    updateUserProfile,
    changeEmailAddress,
    cancelChangeEmailAddress,
    sendVerificationEmail,
    resendChangeEmailAddressVerificationEmail,
    sendUpdatePasswordEmail,
    leaveOrganization,
    hasCurrentUserOtpCredentials,
    disableOtpForCurrentUser
  };
};

export default useUsersApi;
