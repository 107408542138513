/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  SLRPrompt,
  SLRPromptProps,
  SLRSpinner,
  SLRTable,
  getIcon,
  hasValue,
  showErrorToast
} from '@SLR/shared-library';
import { UserMembershipInfo } from '@SLR/user-service-full-sdk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SIZE_PER_PAGE } from 'configs';
import {
  useGetAllMembersOfOrganization,
  useRemoveUserFromOrganization,
  useSetOrganizationRolesForUser
} from 'hooks';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Column, Row } from 'react-table';
import { InviteModal, ListAction } from '.';

type MembersListProps = {
  organizationId?: string;
  isAdmin?: boolean;
};

const MembersList: FC<MembersListProps> = ({ organizationId, isAdmin }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'organizationsPage.details'
  });

  const {
    data: members,
    isPending,
    isError
  } = useGetAllMembersOfOrganization(organizationId ?? '');

  useEffect(() => {
    if (isError) {
      showErrorToast('toasts.loadOrganizationMembers.error');
    }
  }, [isError]);

  const { mutate: removeUser } = useRemoveUserFromOrganization();
  const { mutate: setRole } = useSetOrganizationRolesForUser();

  const [showInvitationModal, setShowInvitationModal] = useState(false);

  const [promptData, setPromptData] = useState<SLRPromptProps | null>(null);
  const hidePrompt = () => setPromptData(null);

  const getMember = useCallback(
    (id: string) => members?.find((m) => m.userId === id)?.userName,
    [members]
  );

  const handleRemove = useCallback(
    (userId: string) => {
      setPromptData({
        confirmButtonVariant: 'danger',
        labels: t('removeMember', {
          returnObjects: true,
          name: getMember(userId)
        }),

        onConfirm: () => {
          if (organizationId) {
            removeUser({ organizationId, userId });
          }
          hidePrompt();
        },
        onCancel: hidePrompt
      });
    },
    [getMember, organizationId, removeUser, t]
  );

  const handleUpdateRole = useCallback(
    (userId: string, role: string) => {
      setPromptData({
        labels: t('updateRole', {
          returnObjects: true,
          name: getMember(userId),
          role: t(`${role as 'admin' | 'member'}`)
        }),
        onConfirm: () => {
          if (organizationId) {
            // eslint-disable-next-line
            // @ts-ignore
            setRole({ organizationId, userId, requestBody: [role] });
          }
          hidePrompt();
        },
        onCancel: hidePrompt
      });
    },
    [getMember, organizationId, setRole, t]
  );

  const columns: Column[] = useMemo(() => {
    const cols = [
      {
        Header: t('name'),
        accessor: 'userName'
      },
      {
        Header: t('role'),
        accessor: 'membershipRoles',
        Cell: ({ value }: { value: 'admin' | 'member' }) => t(`${value}`)
      }
    ] as Column[];

    if (isAdmin) {
      cols.splice(1, 0, {
        Header: t('email'),
        accessor: 'emailAddress'
      });

      cols.push({
        Header: () => (
          <div className="d-none" aria-hidden>
            {t('listOptions')}
          </div>
        ),
        title: t('listOptions'),
        id: 'action',
        Cell: ({ row }: { row: Row }) => {
          const member = row.original as UserMembershipInfo;
          return (
            <ListAction
              id={member.userId}
              currentRole={member.membershipRoles?.toString() ?? 'member'}
              onDelete={handleRemove}
              onRoleChange={handleUpdateRole}
            />
          );
        }
      } as Column);
    }
    return cols;
  }, [t, isAdmin, handleRemove, handleUpdateRole]);

  if (isPending) return <SLRSpinner height="5rem" />;

  return (
    <>
      {promptData && <SLRPrompt {...promptData} />}
      {isAdmin && organizationId && showInvitationModal && (
        <InviteModal
          organizationId={organizationId}
          onClose={() => setShowInvitationModal(false)}
        />
      )}
      {hasValue(members) && (
        <Container className="table-responsive-md py-6">
          <SLRTable
            responsive="md"
            columns={columns}
            data={members}
            pageSize={SIZE_PER_PAGE}
            maxPages={5}
            enablePagination
            sortIcon={getIcon('fal', 'long-arrow-up')}
            tableHeaderProps={{
              title: t('memberCount', {
                count: members?.length ?? 0
              }),
              children: isAdmin ? (
                <Button
                  variant="outline-primary"
                  size="lg"
                  onClick={() => setShowInvitationModal(true)}
                >
                  <FontAwesomeIcon
                    icon={getIcon('fal', 'plus')}
                    size="lg"
                    className="me-2"
                  />
                  {t('inviteUser')}
                </Button>
              ) : undefined
            }}
          />
        </Container>
      )}
    </>
  );
};

export default MembersList;
