/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { showErrorToast, showSuccessToast } from '@SLR/shared-library';
import {
  CreateInvitationRequestDto,
  GlobalErrorResponse,
  ResponseError
} from '@SLR/user-service-full-sdk';
import { useMutation } from '@tanstack/react-query';
import {
  APP_URL,
  INVITATION_ALREADY_MEMBER_ERROR,
  INVITATION_PENDING_ERROR,
  ORGANIZATIONS_PATH,
  queryClient,
  queryKeys
} from 'configs';
import { t } from 'i18next';
import { useInvitationsApi } from 'services';

const useCreateInvitation = (organizationId: string) => {
  const { createInvitation } = useInvitationsApi();

  return useMutation<void, ResponseError, CreateInvitationRequestDto>({
    mutationFn: (createInvitationRequestDto) =>
      createInvitation({
        organizationId,
        createInvitationRequestDto
      }),

    onSuccess: (_, inviteUserRequestDto) => {
      showSuccessToast(
        t('toasts.createInvitation.success', {
          returnObjects: true,
          email: inviteUserRequestDto.invitedUser
        })
      );
      queryClient.invalidateQueries({
        queryKey: [queryKeys.getPendingInvitations, organizationId]
      });
    },
    onError: async (error, inviteUserRequestDto) => {
      const ex: GlobalErrorResponse = await (
        error as ResponseError
      ).response.json();
      showErrorToast({
        title: t('toasts.createInvitation.error.title'),
        content:
          ex.uniqueExceptionType === INVITATION_ALREADY_MEMBER_ERROR
            ? t('toasts.createInvitation.error.alreadyMember', {
                email: inviteUserRequestDto.invitedUser
              })
            : ex.uniqueExceptionType === INVITATION_PENDING_ERROR
              ? t('toasts.createInvitation.error.alreadyInvited', {
                  email: inviteUserRequestDto.invitedUser,
                  url: `${APP_URL}${ORGANIZATIONS_PATH}`
                })
              : undefined
      });
    }
  });
};

const useInviteUsers = (organizationId: string) => {
  const { mutate: inviteUser } = useCreateInvitation(organizationId);

  return useMutation({
    mutationFn: async (invitations: CreateInvitationRequestDto[]) => {
      await invitations.map((createInvitationRequestDto) =>
        inviteUser(createInvitationRequestDto)
      );
    }
  });
};

export default useInviteUsers;
