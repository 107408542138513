/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  EMAIL_PATTERN_VALIDATION,
  SLRFormInfo,
  SLRModal,
  SLRTooltip,
  getIcon
} from '@SLR/shared-library';
import { CreateInvitationRequestDto } from '@SLR/user-service-full-sdk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ALLOWED_ROLES } from 'configs';
import { useInviteUsers } from 'hooks';
import { FC, useCallback } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import './InviteModal.scss';

type InviteModalProps = {
  organizationId: string;
  onClose: () => void;
};

const InviteModal: FC<InviteModalProps> = ({ organizationId, onClose }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'organizationsPage.details.invite'
  });

  const { mutate: inviteUsers, isPending } = useInviteUsers(organizationId);

  const {
    formState: { isDirty, errors, isValid },
    control,
    handleSubmit,
    register,
    getValues
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      invitedUsers: [{ invitedUser: '', role: 'member' }]
    }
  });

  const { append, remove, fields } = useFieldArray({
    control,
    name: 'invitedUsers'
  });

  const handleClickAddRow = useCallback(
    (): void => append({ invitedUser: '', role: 'member' }),
    [append]
  );

  const handleRemoveRow = useCallback(
    (index: number) => () => remove(index),
    [remove]
  );

  const onSubmit = useCallback(
    (formData: { invitedUsers: CreateInvitationRequestDto[] }) => {
      inviteUsers(formData.invitedUsers, { onSuccess: onClose });
    },
    [inviteUsers, onClose]
  );

  return (
    <SLRModal
      isOpen
      isLoading={isPending}
      title={t('title')}
      footerContent={
        <Row className="w-100 m-0 gap-3 align-items-center justify-content-end">
          <Col xs="auto">
            <SLRFormInfo className="w-auto" text={t('mandatoryField')} />
          </Col>
          <Col xs="12" sm="auto" className="p-0 text-end">
            <Button
              size="lg"
              disabled={!(isDirty && isValid)}
              onClick={handleSubmit(onSubmit)}
            >
              <FontAwesomeIcon
                icon={getIcon('fal', 'paper-plane')}
                size="lg"
                className="me-2"
              />
              {t('send')}
            </Button>
          </Col>
        </Row>
      }
      onClose={onClose}
      size="lg"
      className="invite-modal"
    >
      <>
        <Form className="addEntriesForm" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <Row>
                <Col xs={7}>
                  <Form.Label htmlFor="email">{t('email')}*</Form.Label>
                </Col>
                <Col xs={5}>
                  <Form.Label htmlFor="role">
                    {t('role')}
                    <SLRTooltip
                      content={ALLOWED_ROLES.map((role) => (
                        <Trans
                          t={t}
                          key={role}
                          i18nKey={`roleTooltip.${role as 'member' | 'admin'}`}
                        />
                      ))}
                      trigger={['hover', 'focus']}
                      placement="top"
                    >
                      <FontAwesomeIcon
                        icon={getIcon('fal', 'question-circle')}
                        className="roleHelpIcon"
                      />
                    </SLRTooltip>
                  </Form.Label>
                </Col>
              </Row>
            </Col>
            {fields.length > 1 && <Col xs="auto" />}
          </Row>
          {fields.map((_, index: number) => (
            <Row key={index} className="newEntryRow">
              <Col>
                <Row>
                  <Col xs={7}>
                    <Form.Control
                      id="email"
                      size="lg"
                      placeholder={t('emailPlaceholder')}
                      type="text"
                      isInvalid={!!errors?.invitedUsers?.[index]?.invitedUser}
                      {...register(`invitedUsers.${index}.invitedUser`, {
                        required: true,
                        validate: (value) => {
                          if (!EMAIL_PATTERN_VALIDATION.test(value)) {
                            return t('invalidEmail');
                          } else if (
                            getValues().invitedUsers.filter(
                              (user) => user.invitedUser === value
                            ).length > 1
                          ) {
                            return t('existingEmail');
                          } else {
                            return true;
                          }
                        }
                      })}
                    />
                    <SLRFormInfo
                      text={errors?.invitedUsers?.[index]?.invitedUser?.message}
                      isInvalid={!!errors?.invitedUsers?.[index]?.invitedUser}
                    />
                  </Col>
                  <Col xs={5}>
                    <Form.Select
                      id="role"
                      size="lg"
                      {...register(`invitedUsers.${index}.role`, {
                        required: true
                      })}
                      isInvalid={!!errors?.invitedUsers?.[index]?.role}
                    >
                      {ALLOWED_ROLES.map((role) => (
                        <option key={role} value={role}>
                          {t(`${role as 'member' | 'admin'}`)}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
              </Col>
              <Col
                xs="auto"
                className="d-flex justify-content-center align-items-center p-0"
              >
                {fields.length > 1 && (
                  <FontAwesomeIcon
                    icon={getIcon('fal', 'times-circle')}
                    size="lg"
                    className="remove-invitedUser p-1"
                    onClick={handleRemoveRow(index)}
                  />
                )}
              </Col>
            </Row>
          ))}

          <Row>
            <Col>
              <Button
                variant="link"
                size="lg"
                onClick={handleClickAddRow}
                className="px-0"
              >
                <FontAwesomeIcon
                  icon={getIcon('fal', 'plus')}
                  size="lg"
                  className="me-2"
                />
                {t('addMore')}
              </Button>
            </Col>
          </Row>
          {/* Add an invisible form submit button to ensure pressing enter submits the form */}
          <input
            type="submit"
            className="d-none"
            aria-hidden="true"
            title={t('send')}
          />
        </Form>
      </>
    </SLRModal>
  );
};

export default InviteModal;
