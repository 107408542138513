/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRModal, isEmptyOrNull } from '@SLR/shared-library';
import { default as MarkdownPreview } from '@uiw/react-markdown-preview';
import { FC, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './OrganizationDescription.scss';

type OrganizationDescriptionProps = {
  name?: string;
  descriptionText?: string;
  detailedDescription?: string;
};

const OrganizationDescription: FC<OrganizationDescriptionProps> = ({
  name,
  descriptionText,
  detailedDescription
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'organizationsPage.details'
  });

  const [showModal, setShowModal] = useState<boolean>(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <Container fluid className="p-0 organization-description">
      {showModal && (
        <SLRModal
          isOpen
          title={t('aboutUs')}
          onHide={handleCloseModal}
          onClose={handleCloseModal}
          size="xl"
          fullscreen="md-down"
          className="organization-description-modal"
        >
          <Row className="p-0 p-sm-4">
            <Col>
              <h2 className="mb-3">{name}</h2>

              <MarkdownPreview
                className="markdown-style detailed-description"
                source={detailedDescription ?? ''}
                wrapperElement={{ 'data-color-mode': 'light' }}
              />
            </Col>
          </Row>
        </SLRModal>
      )}
      <Container>
        <Row className="justify-content-center">
          <Col
            xs={12}
            lg={8}
            className="d-flex justify-content-center align-items-center py-5"
          >
            <Card className="px-3 description">
              <Card.Body className="px-2">
                <Card.Title className="title my-3">
                  {t('description')}
                </Card.Title>
                <Card.Text className="text mt-1">{descriptionText}</Card.Text>
              </Card.Body>
              {!isEmptyOrNull(detailedDescription) && (
                <Card.Footer className="px-0 border-0 bg-transparent d-flex justify-content-end">
                  <Button
                    variant="link"
                    className="px-0"
                    onClick={handleOpenModal}
                  >
                    <u>{t('learnMore')}</u>
                  </Button>
                </Card.Footer>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default OrganizationDescription;
