/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  EMAIL_PATTERN_VALIDATION,
  URL_PATTERN_VALIDATION,
  isEmptyOrNull
} from '@SLR/shared-library';
import { CreateOrganizationRequestDto } from '@SLR/user-service-full-sdk';
import { t } from 'i18next';
import { ObjectSchema, mixed, object, string } from 'yup';

export const MAX_CHAR_LENGTH = 255;
export const NAME_MAX_CHAR_LENGTH = 200;
export const DESCRIPTION_MAX_CHAR_LENGTH = 400;
export const DETAILED_DESCRIPTION_MAX_CHAR_LENGTH = 5000;

export type OrganizationInput = Nullable<CreateOrganizationRequestDto>;

export const OrganizationInputSchema = (
  isUserOrganization: boolean
): ObjectSchema<OrganizationInput> =>
  object().shape({
    name: string()
      .required(
        t('organizationsPage.createEdit.required', {
          field: t('organizationsPage.createEdit.name')
        })
      )
      .max(
        NAME_MAX_CHAR_LENGTH,
        t('organizationsPage.createEdit.maxLength', {
          count: NAME_MAX_CHAR_LENGTH
        })
      )
      .test(
        'isEmpty',
        t('organizationsPage.createEdit.required', {
          field: t('organizationsPage.createEdit.name')
        }),
        (value) => !isEmptyOrNull(value)
      ),
    description: string()
      .required(
        t('organizationsPage.createEdit.required', {
          field: t('organizationsPage.createEdit.description')
        })
      )
      .max(
        DESCRIPTION_MAX_CHAR_LENGTH,
        t('organizationsPage.createEdit.maxLength', {
          count: DESCRIPTION_MAX_CHAR_LENGTH
        })
      )
      .test(
        'isEmpty',
        t('organizationsPage.createEdit.required', {
          field: t('organizationsPage.createEdit.description')
        }),
        (value) => !isEmptyOrNull(value)
      ),
    email: string()
      .matches(
        EMAIL_PATTERN_VALIDATION,
        t('organizationsPage.createEdit.invalidEmail')
      )
      .max(
        MAX_CHAR_LENGTH,
        t('organizationsPage.createEdit.maxLength', {
          count: MAX_CHAR_LENGTH
        })
      )
      .test(
        'isRequired',
        t('organizationsPage.createEdit.required', {
          field: t('email')
        }),
        (value) => isUserOrganization || !isEmptyOrNull(value)
      )
      .nullable(),
    websiteUrl: string()
      .matches(
        URL_PATTERN_VALIDATION,
        t('organizationsPage.createEdit.invalidUrl')
      )
      .max(
        MAX_CHAR_LENGTH,
        t('organizationsPage.createEdit.maxLength', {
          count: MAX_CHAR_LENGTH
        })
      )
      .nullable(),
    detailedDescription: string().nullable(),
    address: string()
      .max(
        MAX_CHAR_LENGTH,
        t('organizationsPage.createEdit.maxLength', {
          count: MAX_CHAR_LENGTH
        })
      )
      .nullable(),
    phoneNumber: string()
      .max(
        MAX_CHAR_LENGTH,
        t('organizationsPage.createEdit.maxLength', {
          count: MAX_CHAR_LENGTH
        })
      )
      .nullable(),
    relatedGeoAreaIds: mixed<Set<string>>().nullable(),
    mediaItemId: string().nullable()
  });
