/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

export const invitationsPage = {
  failedAccept:
    'Die Einladung konnte nicht akzeptiert werden! Bitte versuche später erneut.',
  loadingError:
    'Es tut uns leid! Leider konnten die Daten nicht abgerufen werden. Bitte versuche später erneut.',
  invalid: {
    title: 'Link ungültig :(',
    description:
      'Es tut uns leid! Der Einladungslink ist leider nicht mehr gültig. Möglicherweise ist die Einladung abgelaufen oder wurde zurückgezogen.',
    options:
      'Kontaktiere die Person, die dich eingeladen hat, um eine neue Einladung zu erhalten.'
  },
  success: {
    title: 'Erfolgreich beigetreten',
    description:
      'Du bist erfolgreich der Organisation "{{organization}}" beigetreten.',
    options: 'Du musst nichts weiter tun.',
    manage: 'Mitgliedschaft verwalten'
  },
  handle: {
    title: 'Organisation beitreten',
    description:
      'Möchtest du mit dem folgenden Konto der Organisation "{{organization}}" beitreten?',
    userPictureAlt: 'Profilbild',
    acceptCurrentAccount: 'Organisation beitreten',
    acceptDifferentAccount: 'Mit anderem Konto beitreten'
  }
};
