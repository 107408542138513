/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { FC, memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CreateOrganizationButton } from '..';

import './EmptyOrganizations.scss';

type EmptyOrganizationsProps = {
  onCreate: () => void;
};

const EmptyOrganizations: FC<EmptyOrganizationsProps> = ({ onCreate }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'organizationsPage.empty'
  });

  return (
    <div className="d-flex flex-column align-items-center justify-content-center empty-organizations">
      <div className="d-none d-md-block background-image" />
      <Row className="fs-3 gap-4 text-center rounded-circle content">
        <Col xs="12">{t('top')}</Col>
        <Col xs="12">{t('bottom')}</Col>
      </Row>
      <div className="mt-5" style={{ zIndex: 1000 }}>
        <CreateOrganizationButton onClick={() => onCreate()} />
      </div>
    </div>
  );
};

export default memo(EmptyOrganizations);
