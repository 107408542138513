/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

const name = 'Name';
const email = 'E-Mail';
const role = 'Rolle';
const admin = 'Admin';
const member = 'Mitglied';

export const organizationsPage = {
  organizations: 'Organisationen',
  description:
    'Verwalte deine Mitgliedschaft in Organisationen zentral für alle Lösungen bei DEUTSCHLAND.DIGITAL.',
  memberships: 'Mitgliedschaften',
  members: 'Mitglieder',
  name: name,
  role: role,
  admin: admin,
  member: member,
  numberOfMembers: 'Mitgliederanzahl',
  create: 'Organisation erstellen',
  edit: 'Organisation bearbeiten',
  invitation: {
    title: 'Möchtest du "{{organizationName}}" beitreten?',
    contentTop:
      'Jemand hat dich zur Organisation "{{organizationName}}" eingeladen.',
    contentBottom: 'Möchtest du der Organisation beitreten?',
    accept: 'Organisation beitreten',
    reject: 'Ablehnen'
  },
  empty: {
    top: 'Du bist noch keiner Organisation beigetreten.',
    bottom:
      'Hier können sich Organisationen wie Unternehmen oder Vereine ein zentrales Profil für alle Lösungen erstellen.'
  },
  info: {
    title: 'Über Organisationen',
    content: {
      title: 'Wofür sind Organisationen?',
      message:
        'Über dein Konto kannst du Organisationen – zum Beispiel für deinen Verein oder dein Unternehmen – zentral erstellen und verwalten.<br/><br/>Dank der zentralen Verwaltung können angebundene Lösungen Informationen wie den Beschreibungstext oder das Logo der Organisation übernehmen oder Mitgliedern automatisch Rechte erteilen (beispielsweise Zugang zu einer Lösung). Dadurch entfällt die mühsame Aufgabe, in jeder Lösung dieselbe Organisation und deren Mitglieder erneut anzulegen.<br/><br/>  Innerhalb einer Organisation gibt es zwei Rollen. Admins haben die Berechtigung, die Organisation zu verwalten und weitere Personen einzuladen. Normale Mitglieder können zwar auf die Profilseite der Organisation zugreifen, jedoch keine Änderungen vornehmen.'
    }
  },
  createEdit: {
    new: 'Neue Organisation erstellen',
    edit: 'Organisation bearbeiten',
    create: 'Organisation erstellen',
    logoPicture: 'Logo der Organisation',
    name: 'Name der Organisation',
    description: 'Öffentliche Beschreibung',
    logo: {
      deletionConfirmationTitle: 'Logo entfernen?',
      deletionConfirmationQuestion:
        'Soll dein Organisationslogo gelöscht werden?',
      acceptDeletionConfirmation: 'Ja, entfernen!',
      declineDeletionConfirmation: 'Nein',
      discardChanges: 'Verwerfen',
      acceptChanges: 'Übernehmen',
      tooLargeFile: {
        title: 'Bild zu groß (max. 5 MB)',
        content:
          'Das ausgewählte Bild ist leider zu groß. Bitte wähle ein Bild mit maximal 5 MB aus.'
      },
      invalidFileType: {
        title: 'Dateiformat nicht unterstützt',
        content:
          'Leider unterstützen wir das Format der ausgewählten Datei nicht. Bitte wähle ein Bild im Format “jpg”, “jpeg” oder “png”.'
      },
      uploadLogoAlt: 'Rundes Bild einer Kamera',
      defaultAlt: 'Rundes Organisationslogo',
      uploadButtonLabel:
        'Taste zur Auswahl eines neuen Organisationslogos, welches hochgeladen werden soll',
      deleteButtonLabel: 'Lösch-Taste',
      rotateButtonLabel: 'Dreh-Taste'
    },
    shortDescription: 'Kurzfassung',
    detailedDescription: 'Langfassung',
    websiteUrl: 'Webseite',
    phoneNumber: 'Telefon',
    address: 'Adresse',
    email: email,
    required: '{{field}} ist ein Pflichtfeld.',
    counter: '{{count}} / {{maxCount}} Zeichen',
    maxLength: 'Maximale Zeichenlänge von {{count}} überschritten',
    invalidUrl: 'Ungültige URL',
    invalidEmail: 'Ungültige E-Mail-Adresse',
    save: 'Übernehmen',
    mandatoryField: '*Pflichtfeld'
  },
  details: {
    options: {
      menuLabel: 'Einstellungen der Organization',
      edit: 'Organisation bearbeiten',
      delete: 'Organisation löschen',
      leave: 'Organisation verlassen',
      manageKey: 'Schlüssel verwalten'
    },
    keyManagement: {
      title: 'Schlüssel verwalten',
      keyFileNamePrefix: 'DEUTSCHLAND.DIGITAL-Key',
      keyFileName:
        'DEUTSCHLAND.DIGITAL-Key_{{organizationName}}_{{timestamp}}.asc',
      createdOn: 'Erstellt am {{date}} um {{time}} Uhr',
      expiresOn: 'Gültig bis {{date}} um {{time}} Uhr',
      create: {
        title: 'Schlüssel erstellen',
        description:
          'Bestimmte Lösungen, z. B. aus dem medizinischen Bereich, erfordern einen besonderen Schutz sensibler Daten. Ein digitaler Schlüssel wird zur Verschlüsselung verwendet, um den Zugriff auf autorisierte Personen zu beschränken.<br/><br/>Erstelle hier einen Schlüssel für deine Organisation. Der Schlüssel gilt für alle Mitglieder und kann in entsprechenden Lösungen verwendet werden.'
      },
      manage: {
        title: 'Schlüssel verwalten',
        description:
          'Bestimmte Lösungen, z. B. aus dem medizinischen Bereich, erfordern einen besonderen Schutz sensibler Daten. Ein digitaler Schlüssel wird zur Verschlüsselung verwendet, um den Zugriff auf autorisierte Personen zu beschränken.<br/><br/>Verwalte hier den Schlüssel deiner Organisation. Der Schlüssel gilt für alle Mitglieder und kann in entsprechenden Lösungen verwendet werden.'
      },
      delete: {
        title: 'Schlüssel löschen?',
        content:
          'Ohne Schlüssel ist der Zugriff auf Lösungen und Daten, die einen Schlüssel erfordern, nicht mehr möglich. Möchtest du fortfahren?',
        cancel: 'Abbrechen',
        confirm: 'Schlüssel löschen'
      },
      refresh: {
        title: 'Schlüssel erneuern?',
        content:
          'Der alte Schlüssel wird durch einen neuen unwiderruflich ersetzt. Möchtest du fortfahren?',
        cancel: 'Abbrechen',
        confirm: 'Schlüssel erneuern'
      },
      button: {
        create: 'Schlüssel erstellen',
        download: 'Herunterladen',
        refresh: 'Erneuern',
        delete: 'Löschen'
      },
      toast: {
        keyExpired: {
          title: 'Schlüssel nicht mehr gültig!',
          content:
            'Bitte erneuere den Schlüssel, um weiterhin auf Lösungen zuzugreifen, welche einen gültigen Schlüssel voraussetzen.'
        },
        newKeyGenerated: {
          title: 'Wichtig: Neuen Schlüssel jetzt herunterladen!',
          content:
            'Lade den Schlüssel herunter, bevor du das Fenster schließt. Danach ist kein Zugriff mehr möglich. Verteile den Schlüssel nur über sichere Kanäle an andere (z. B. als verschlüsselte E-Mail).'
        },
        oldKeyRefreshed: {
          title: 'Hebe den alten Schlüssel gut auf!',
          content:
            'Es ist möglich, dass du den alten Schlüssel erneut benötigst, um auf ältere Daten zugreifen zu können. Hebe den Schlüssel deshalb weiterhin auf.'
        },
        email: {
          title: 'E-Mail-Adresse der Organisation benötigt',
          content:
            'Um einen Schlüssel zu erstellen, wird eine E-Mail-Adresse für die Organisation vorausgesetzt. Bitte bearbeite das Profil deiner Organisation und füge eine E-Mail-Adresse hinzu.',
          button: 'Organisationsprofil bearbeiten'
        }
      }
    },
    noAccess:
      'Du scheinst nicht die erforderlichen Rechte zu haben um diese Organisation aufzurufen oder diese Organisation existiert nicht.',
    navigateToOverview: 'Zurück zur Übersicht',
    organizationLogoAlt: 'Logo der Organization {{organization}}',
    description: 'Beschreibung',
    learnMore: 'Mehr erfahren...',
    aboutUs: 'Über uns',
    noMemberName: 'Name nicht angegeben',
    inviteUser: 'Person einladen',
    memberCount_one: '{{count}} Mitglied',
    memberCount_other: '{{count}} Mitglieder',
    invitationCount_one: '{{count}} Einladung',
    invitationCount_other: '{{count}} Einladungen',
    email: email,
    name: name,
    role: role,
    admin: admin,
    member: member,
    listOptions: 'Optionen',
    listActions: {
      menuLabel: 'Optionen',
      admin: admin,
      member: member,
      changeRole: 'Rolle ändern',
      delete: 'Entfernen',
      resendInvitation: 'Erneut einladen'
    },
    invite: {
      title: 'Person einladen',
      email: 'E-Mail',
      send: 'Einladung verschicken',
      addMore: 'Weitere hinzufügen',
      emailPlaceholder: 'E-Mail Adresse',
      requiredEmail: 'E-Mail-Adresse darf nicht leer sein.',
      invalidEmail: 'Ungültige E-Mail-Adresse',
      existingEmail: 'Diese E-Mail-Adresse wurde bereits eingegeben.',
      role: role,
      admin: admin,
      member: member,
      roleTooltip: {
        admin:
          '<strong>Admin:</strong> Kann sämtliche Einstellungen verändern und Mitglieder hinzufügen. <br>',
        member:
          '<strong>Mitglied:</strong> Kann auf diese Organisation zugreifen.'
      },
      mandatoryField: '*Pflichtfeld'
    },
    delete: {
      title: 'Organisation löschen?',
      content:
        'Durch Löschen der Organisation werden sämtliche Inhalte und Mitglieder der Gruppe unwiderruflich entfernt.',
      cancel: 'Abbrechen',
      confirm: 'Organisation löschen'
    },
    leave: {
      title: 'Organisation verlassen?',
      content:
        'Wenn du die Organisation verlässt, hast du keinerlei Zugriff mehr auf die Organisation.',
      cancel: 'Abbrechen',
      confirm: 'Organisation verlassen'
    },
    removeMember: {
      title: 'Mitglied entfernen?',
      content:
        '{{name}} wird aus der Organisation entfernt und hat keinerlei Zugriff mehr auf die Organisation.',
      cancel: 'Abbrechen',
      confirm: 'Mitglied entfernen'
    },
    updateRole: {
      title: 'Rolle ändern?',
      content:
        '{{name}}s Rolle in dieser Organisation wird zu "{{role}}" geändert.',
      cancel: 'Abbrechen',
      confirm: 'Rolle ändern'
    },
    removeInvitation: {
      title: 'Einladung zurückziehen?',
      content: 'Die Einladung für {{name}} wird zurückgezogen.',
      cancel: 'Abbrechen',
      confirm: 'Einladung zurückziehen'
    },
    updateInvitationRole: {
      title: 'Rolle ändern?',
      content:
        '{{name}}s Rolle in dieser Organisation wird zu "{{role}}" geändert.',
      cancel: 'Abbrechen',
      confirm: 'Rolle ändern'
    }
  }
};
