/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  SLRLabelIcon,
  SLRMediaItem,
  getIcon,
  useGetOrganizationKey
} from '@SLR/shared-library';
import { Organization, OrganizationType } from '@SLR/user-service-full-sdk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DEFAULT_ORGANIZATION_IMAGE_PATH, ORGANIZATIONS_PATH } from 'configs';
import { useQueryParam } from 'providers';
import { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import OrganizationDropdown from './OrganizationDropdown';

import './OrganizationHeader.scss';

type OrganizationHeaderProps = {
  organization: Organization;
  isMember?: boolean;
  isAdmin?: boolean;
  onEdit: () => void;
  onManageKey: () => void;
  onDelete: () => void;
  onLeave: () => void;
};

const OrganizationHeader: FC<OrganizationHeaderProps> = ({
  organization,
  isMember,
  isAdmin,
  onManageKey,
  onEdit,
  onLeave,
  onDelete
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'organizationsPage.details'
  });

  const { data: organizationKey } = useGetOrganizationKey(organization.id);
  const { solutionBarQuery } = useQueryParam();
  const { showEncryption } = solutionBarQuery || {};

  const dropDown = (
    <OrganizationDropdown
      isAdmin={isAdmin}
      isMember={isMember}
      isUserOrganization={organization?.type === OrganizationType.User}
      showEncryption={Boolean(
        (isAdmin && organizationKey?.key) || showEncryption
      )}
      onManageKey={onManageKey}
      onEdit={onEdit}
      onDelete={onDelete}
      onLeave={onLeave}
    />
  );

  return (
    <Container className="organization-details">
      <Row className="py-4 justify-content-between align-items-center organization-header">
        <Col
          xs="12"
          md="4"
          lg="3"
          className="d-flex align-items-center justify-content-between mb-2 mb-md-0"
        >
          <Link
            to={ORGANIZATIONS_PATH}
            title={t('navigateToOverview')}
            className="btn btn-link"
          >
            <FontAwesomeIcon icon={getIcon('fal', 'chevron-left')} size="3x" />
          </Link>
          <SLRMediaItem
            mediaItemId={organization.logoId}
            altFallback={t('organizationLogoAlt', {
              organization: organization.name
            })}
            errorFallback={DEFAULT_ORGANIZATION_IMAGE_PATH}
            roundedCircle
            className="logo"
          />
          <div className="d-block d-md-none">{dropDown}</div>
        </Col>

        <Col xs="12" md="7" lg="8">
          <Row className="name fs-1 text-center text-md-start">
            <Col>{organization?.name}</Col>
          </Row>

          {(organization?.websiteUrl ||
            organization?.email ||
            organization?.phoneNumber ||
            organization?.address) && (
            <Row className="pt-3 gap-2 tags">
              {organization?.websiteUrl && (
                <SLRLabelIcon
                  label={organization?.websiteUrl}
                  link={organization?.websiteUrl}
                  target={'_blank'}
                  icon={getIcon('fal', 'globe')}
                />
              )}
              {organization?.email && (
                <SLRLabelIcon
                  label={organization?.email}
                  link={`mailto:${organization?.email}`}
                  icon={getIcon('fal', 'envelope-circle')}
                />
              )}
              {organization?.phoneNumber && (
                <SLRLabelIcon
                  label={organization?.phoneNumber}
                  link={`tel:${organization?.phoneNumber}`}
                  icon={getIcon('fal', 'phone-circle')}
                />
              )}
              {organization?.address && (
                <SLRLabelIcon
                  label={organization?.address}
                  icon={getIcon('fal', 'location-dot')}
                />
              )}
            </Row>
          )}
        </Col>

        <Col xs="auto" md="1" className="text-end d-none d-md-block">
          {dropDown}
        </Col>
      </Row>
    </Container>
  );
};
export default OrganizationHeader;
