/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Pedram (Majid) Jokar, Mher Ter-Tovmasyan,
 * Bestin John, Steffen Hupp, Anna Kleiner, Rafael Aranda Lopez King, Emily Calvet, Philipp Ewen,
 * Matthias Gerbershagen, Milad Chatrangoon, Stefan Schweitzer
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { SLRLegalRoute } from '@SLR/shared-library';
import { LegalTextType } from '@SLR/user-service-full-sdk';

export const CHOOSE_ORGANIZATION_PATH = '/choose/organization';

// Invitation paths
export const INVITATION_CHECK = '/invitations';
export const INVITATION_HANDLE = '/handle-invitations';

// Legal paths
export const LEGAL_PATH = '/legal';
export const PRIVACY_POLICY_PATH = 'privacy-policy';
export const TERMS_OF_USE_PATH = 'terms-of-use';
export const IMPRINT_PATH = 'imprint';

export const legalRouts: SLRLegalRoute[] = [
  {
    path: PRIVACY_POLICY_PATH,
    legalTextType: LegalTextType.DataPrivacy
  },
  {
    path: TERMS_OF_USE_PATH,
    legalTextType: LegalTextType.Terms
  },
  {
    path: IMPRINT_PATH,
    legalTextType: LegalTextType.Imprint
  }
];

// Organization paths
export const ORGANIZATIONS_PATH = '/organizations';
export const ORGANIZATION_DETAILS_PATH = ':organizationId';

// Profile paths
export const PROFILE_PATH = '/profile';
export const PROFILE_PASSWORD_UPDATE_PATH = 'password';
export const PROFILE_EMAIL_UPDATE_PATH = 'email';
export const PROFILE_SECURITY_PATH = 'security';
export const PROFILE_DATA_PRIVACY_PATH = 'privacy';

export const DELETED_PROFILE_PATH = '/deleted-profile';
